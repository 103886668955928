import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/articles/checkYourAdvertising/1-2.gif";
import second from "../../../../image/articles/checkYourAdvertising/1-1.gif";
import third from "../../../../image/articles/checkYourAdvertising/2-2.gif";
import fourth from "../../../../image/articles/checkYourAdvertising/2-1.gif";
import fifth from "../../../../image/articles/checkYourAdvertising/3-1.gif";
import sixth from "../../../../image/articles/checkYourAdvertising/3-2.gif";
import seventh from "../../../../image/articles/checkYourAdvertising/4-1.gif";
import thumbNail from "../../../../image/articles/checkYourAdvertising/img_blog_iris.png";
import irisIcon from "../../../../image/articles/checkYourAdvertising/iris.png";
export default function CheckYourAdvertising() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thumbNail} alt="thumbnail" />
      <h1 className={styles.title}>
        Check Your Advertising Videos First to Save Costs!
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={irisIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Iris</span>
            <span>•</span>
            <span>Creative Team</span>
          </span>
          <span content="date">2023.07.27</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          When reviewing game ads, do you know which data is associated with
          game installation? It's the Cost Per Install (CPI). CPI refers to the
          amount of money spent when a user views an advertisement, resulting in
          game installation. Therefore, the lower the CPI, the more people can
          install the game with less advertising expense. Understanding which
          video elements can help decrease the CPI and which ones to avoid
          incorporating into your creative is crucial. Based on our experience,
          I'll provide some helpful tips on effectively reducing the CPI in your
          game in this blog post.
        </p>
        <h2>
          1. Avoid using backgrounds that are overly colorful or cluttered with
          too many objects.
        </h2>
        <p>
          When it comes to advertising videos, it's essential to ensure viewers
          understand how to play the game. Including too many design elements
          can distract the viewer from focusing on the gameplay. This is
          exemplified by Popcorn Pop! creative videos below.
        </p>

        <div data-content="2">
          <div>
            <img content="height_2" src={first} alt="" />
            <p>(A)</p>
          </div>
          <div>
            <img content="height_2" src={second} alt="" />
            <p>(B)</p>
          </div>
        </div>
        <p>
          In (A)'s creative video, only basic objects are against a monochrome
          background. On the other hand, (B) has a colorful background and
          various additional objects. However, popcorn, the most important
          object, is not noticeable in (B). Complex backgrounds and objects can
          interfere with the viewer's attention, making them less interested in
          the game's core gameplay or simply what it is about. Therefore,
          keeping the background simple and reducing the number of objects in
          the creative video is better. This will help the viewer understand the
          game video better and increase their likelihood of downloading it. By
          removing colorful backgrounds and complex objects in Popcorn Pop!'s
          creative work, the CPI was reduced by around 47%.
        </p>

        <h2>2. Removing the UI is more effective in lowering the CPI.</h2>
        <p>
          Have you ever paid close attention to game advertisements' user
          interface(UI)? A UI's presence or absence is one factor affecting the
          CPI. Often, ads are created without UI so that viewers can concentrate
          solely on the game's primary content.
        </p>
        <div data-content="2">
          <div>
            <img content="height_2" src={third} alt="" />
            <p>(A)</p>
          </div>
          <div>
            <img content="height_2" src={fourth} alt="" />
            <p>(B)</p>
          </div>
        </div>
        <p>
          The example above demonstrates the correlation between CPI,
          backgrounds and UI in the game Perfect Cone. The first example, (A),
          is a clean video with no UI or intricate background. On the other
          hand, example (B) features a graph paper-like background and multiple
          UIs. The creative video in (B) includes too many elements in one
          scene, making it difficult for viewers to focus on the gameplay during
          a brief advertisement. In contrast, the ad video in (A) enhances
          viewer concentration by excluding all UI elements and showcasing the
          primary gameplay - drawing content. Consequently, we reduced CPI by
          approximately 43% with the inventive approach in (A). To ensure video
          viewers don't miss out on crucial game content, removing any colorful
          backgrounds and UI is best. This allows the viewer to focus entirely
          on the important content without distractions.
        </p>
        <h2>
          3. One way to improve CPI is to use bright color backgrounds that have
          good contrast against objects.
        </h2>
        <p>
          Which color do you choose when selecting the background color for a
          video? It is recommended to opt for bright colors. By incorporating
          bright colors in the background, you can achieve contrast with the
          primary object, which can effectively reduce the CPI.
        </p>

        <div data-content="2">
          <div>
            <img content="height_2" src={fifth} alt="" />
            <p>(A)</p>
          </div>
          <div>
            <img content="height_2" src={sixth} alt="" />
            <p>(B)</p>
          </div>
        </div>
        <p>
          The videos above feature two different backgrounds: bright blue (A)
          and dark (B). When the object and background colors are too similar,
          like in (B), the background becomes more prominent than the main
          object, bullets and bricks. However, video (A) uses a bright
          background which allows the viewer to focus on the main object,
          bullets, quickly and simultaneously creates a contrast with the
          bricks. As a result, using the bright sky-blue background led to a 49%
          lower CPI for the game compared to using a colorful or dark
          background.
        </p>
        <h2>
          4. Create a sense of curiosity in the viewer to incite their interest
          in playing the game.
        </h2>
        <p>
          When advertising your game, it's important to showcase its best
          features without giving away too much. To generate interest and
          encourage downloads, offering a sneak peek of your game's most
          appealing aspects is a wise choice.
        </p>
        <div data-content="1">
          <div>
            <img content="height_1" src={seventh} alt="" />
          </div>
        </div>

        <p>
          The method used in Match Gun 3D was to expose the prize the player can
          obtain at the end of the run. The main character fails to reach the
          award in the creative video example above. The point of the video was
          to make the viewer wonder what would happen if they got the big golden
          gun at the end of the road. Even if you don't have unique objects in
          the actual game, it's a good idea to include elements that stimulate
          the viewers' curiosity in the advertising video.
        </p>
        <h2>Conclusion</h2>
        <p>
          When creating a video, you must ask yourself if the viewer can quickly
          identify the game's core gameplay. Pay attention to the layout and
          design of the background and objects to ensure a positive impact on
          CPI. To create compelling videos, consider implementing the four tips
          mentioned earlier and strive to produce a variety of creative videos
          options.
        </p>
        <p>
          The creative team at MondayOFF assists studios and developers in
          producing CPI videos that capture the essence of their games and
          entice viewers to download them. We are always available to provide
          additional guidance and helpful tips to enhance the quality of your
          creative videos and keep CPI low. Visit our blog page for more
          insights!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
