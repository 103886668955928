import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/howtoknow/MKfirst.png";
import second from "../../../../image/blog/writing/howtoknow/MKsecond.png";
import third from "../../../../image/blog/writing/howtoknow/MKthird.png";
import howToKnowMain from "../../../../image/blog/writing/howtoknow/howtoknowmain.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const AccordingToMetrics = (props) => {
  return (
    <article className={styles.container}>
      <img src={howToKnowMain} alt="thumbnail" className={styles.thumbnail} />
      <h1 className={styles.title}>
        How to Know Your Game is Doing Well According to Metrics
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021.11.15</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          As a Developer, when finishing up your prototype and preparing for a
          soft launch, it is crucial to understand the metrics involved in the
          testing stage. CPI, Retention, and eCPM are key metrics that show how
          the audience accepts your game and its potential for making it to the
          top charts. In this blog, you will learn to understand these metrics
          to know if your hyper-casual game is doing well in the market.
        </p>
        <h2>1. The Famous CPI</h2>
        <p>
          CPI (Cost Per Install) is one of the most important metrics when it
          comes to the soft launch of your game. A low CPI shows that your
          hyper-casual game is actively being downloaded when an ad campaign is
          demonstrated to the public. A CPI of $0.35c is favorable and a sign to
          keep working on your game. Of course, the lower the CPI, the better!
        </p>
        <div data-content="1">
          <img content="square_1" src={first} alt="" />
        </div>
        <h2>2. RETENTION (RET)</h2>
        <p>
          When testing your game, Retention varies depending on the days. When
          it comes to soft launch, D-1 RET is extremely important. On the first
          day, the Retention shows if the players like the gameplay. A RET of
          over 45% demonstrates that the game is promising, and players highly
          enjoy the core gameplay. On the first day, if the RET is less than
          30%, then the gameplay must be improved for higher RET and better CPI.
          For that reason, it is crucial to focus on the gameplay of your game
          when developing your prototype.
        </p>
        <p>
          Retention might be the most significant metric when it comes to game
          testing. Without this metric, you can't know if the audience favors
          your game and its potential in the market. It can also tell you why
          and when the players stopped playing for you to improve the game.
        </p>
        <div data-content="1">
          <img content="square_1" src={second} alt="" />
        </div>

        <h2>3. Last but NOT Least, The eCPM</h2>
        <p>
          The eCPM (Effective Cost Per Mille) is another metric often not spoken
          about, but it shows the revenue you have made per 1,000 impressions.
          Of course, knowing this can be super exciting but also disheartening.
          If your game is doing well, then the revenue will cover the Effective
          Cost Per Mille. This metric allows the developer to understand how
          much they will get paid per 1,000 impressions and for the publisher to
          know how much they should spend per 1,000 impressions. The goal is for
          the ROAS (Return on Ad Spend) to be more than 100%.
        </p>
        <div data-content="1">
          <img content="square_1" src={third} alt="" />
        </div>
        <p>
          As a developer, it is crucial to know all these metrics to understand
          how well your game is doing. With the help of your publisher, you will
          be able to understand the metrics and what to do to improve your game
          to bring it to the next level.
        </p>
        <p>
          At MondayOFF, we care about the KPI’s of our partners and give
          opportunities for improvement. We believe in the potential of your
          game and will listen to you while also providing aid and feedback.
          With the help of our publishing managers, as a developer, you will
          know everything you need for your game to be the next big hit!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default AccordingToMetrics;
