import React from "react";
import styles from "../../articles.module.scss";
import CH_1 from "../../../../image/blog/writing/christmas/1.png";
import CH_2 from "../../../../image/blog/writing/christmas/2.png";
import CH_3 from "../../../../image/blog/writing/christmas/3.png";
import CH_4 from "../../../../image/blog/writing/christmas/4.png";
import CH_5 from "../../../../image/blog/writing/christmas/5.png";
import CH_6 from "../../../../image/blog/writing/christmas/6.png";
import CH_7 from "../../../../image/blog/writing/christmas/7.png";
import CH_8 from "../../../../image/blog/writing/christmas/8.png";
import CH_9 from "../../../../image/blog/writing/christmas/9.png";
import CH_10 from "../../../../image/blog/writing/christmas/10.png";
import CH_11 from "../../../../image/blog/writing/christmas/11.png";
import CH_12 from "../../../../image/blog/writing/christmas/12.png";
import christmasMain from "../../../../image/blog/writing/christmas/mainImg.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const MondayOffChristmasParty = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={christmasMain} alt="" />
      <h1 className={styles.title}>A MondayOFF Christmas Party</h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021. 12. 09</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>Ho Ho Ho! Christmas is Here at MondayOFF!</p>
        <p>
          This Christmas, everybody at MondayOFF got to enjoy a jolly good time.
          All the employees had a lot of fun playing fun games with the promise
          of a huge cash prize at the end, Squid Game style. With the promise of
          a luscious award, everyone was fired up to get it! The competition was
          high for each team.
        </p>
        <p>
          Everyone was split up into five teams, and only one of them could
          obtain the piggy bank filled to the brim with money. The night's first
          challenge was a timed quiz, where only one person could get the
          correct answer for their team.
        </p>
        <div data-content="1">
          <img content="square_1" src={CH_1} alt="" />
        </div>

        <p>
          Next up, a tree decorating challenge! The most creative tree shall
          win! It was definitely a challenge to make a tree with what was
          available at the office, so everyone got really creative! During the
          presentation, we truly got to see some fascinating specimens. Some
          looked more like Christmas, and some others looked like something
          undefinable. Creativity was through the roof!
        </p>
        <div data-content="3">
          <img content="height_3" src={CH_2} alt="" />
          <img content="height_3" src={CH_3} alt="" />
          <img content="height_3" src={CH_4} alt="" />
        </div>
        <p>
          After a beautiful tree decorating competition was time for Be a Pong!
          But in real life! (If you get the hint.) This was the true challenge!
          Each team against each other, face to face as if their lives were on
          the line. Although we might not have the best motor skills, we had the
          best time playing this game.
        </p>
        <p>
          The MVP of this game was definitely our full-stack developer. He
          really does know how to calculate!
        </p>
        <div data-content="1">
          <img content="height_1" src={CH_5} alt="" />
        </div>
        <p>
          Our last but not least Christmas Challenge was the gingerbread house
          challenge! For many people, it was their first time making a
          gingerbread house. Coordinating with their teammates was more
          complicated than expected. Gluing the house together might have been
          the most challenging task, but this did not stop anyone from
          completing their home.Finally, the election of the best MondayOFF game
          in 2021. It was a hard decision, but Life of Mellow won the prize in
          the end.
        </p>
        <div data-content="3">
          <img content="square_3" src={CH_6} alt="" />
          <img content="square_3" src={CH_7} alt="" />
          <img content="square_3" src={CH_8} alt="" />
        </div>
        <p>
          Last but not least, the election of the best MondayOFF game in 2021.
          It was a hard decision, but Life of Mellow won the prize in the end.
          (Download Life of Mellow for &nbsp;
          <a
            className={styles.link}
            href="https://apps.apple.com/US/app/id1578810061?l=en"
            target="_blank"
            rel="noreferrer"
          >
            IOS
          </a>
          &nbsp; and&nbsp;
          <a
            className={styles.link}
            href="https://play.google.com/store/apps/details?hl=en&id=com.mondayoff.mellow"
            target="_blank"
            rel="noreferrer"
          >
            Android
          </a>
          !)
        </p>
        <div data-content="1">
          <img content="square_1" src={CH_9} alt="" />
        </div>

        <p>
          Not only that, but also we chose the MVP player and best team of the
          night! They all got more prizes and celebrated joyfully. The piggy
          bank stacked with money might have suffered, but it was all worth it
          for the final award, Squid Game style.
        </p>
        <div data-content="2">
          <img content="square_2" src={CH_10} alt="" />
          <img content="square_2" src={CH_11} alt="" />
        </div>
        <p>
          It was an enjoyable night for everyone, and I hope that we can have
          even more fun next year!
        </p>
        <div data-content="1">
          <img content="square_1" src={CH_12} alt="" />
        </div>

        <p>
          Follow us on &nbsp;{" "}
          <a
            className={styles.link}
            href="https://www.linkedin.com/company/mondayoff-inc/mycompany/"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
          &nbsp; and check our &nbsp;
          <a
            className={styles.link}
            href="https://mondayoff.me"
            target="_blank"
            rel="noreferrer"
          >
            homepage
          </a>
          &nbsp; to stay updated with our games and activities.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default MondayOffChristmasParty;
