import React from "react";

import styles from "./submitsection.module.css";
import zigzag from "../../../../image/img_zigzag.png";
import handShake from "../../../../image/img_handshake.png";
import fistBumpLeft from "../../../../image/img_pow_left.png";
import fistBumpRight from "../../../../image/img_pow_right.png";
import powImg from "../../../../image/img_pow.png";
const SubmitSection = (props) => {
  return (
    <section className={styles.container} id="container">
      <div className={styles.zigzag}>
        <img src={zigzag} alt="zigzag" />
      </div>
      {/*tablet from 1920px*/}
      <div className={styles.cardcontainer}>
        <div className={styles.publishing_container}>
          <div className={styles.card}>
            <div className={styles.content}>
              <h1>Publishing</h1>
              <img
                src={handShake}
                className={styles.handShakeImg}
                alt="handshake"
              />
              <p>
                Game trends change rapidly, but our
                <br /> experience and knowledge evolve accordingly. Let’s work
                together. You’re the next hit!
              </p>
              <a
                href="https://dashboard.mondayoff.me"
                target="_blank"
                rel="noreferrer"
              >
                Submit Your Game
              </a>
            </div>
          </div>
        </div>
        <div className={styles.partnership_container}>
          <div className={styles.card} id="card">
            <div className={styles.content}>
              <h1>Partnership</h1>
              <img
                src={fistBumpLeft}
                className={styles.fistBumpLeft}
                alt="fistbumpleft"
              />
              <img
                src={fistBumpRight}
                className={styles.fistBumpRight}
                alt="fistBumpRight"
              />
              <p>
                Millions of people deserve to play your game. <br />
                Don’t give up on your potential!
              </p>
              <a
                href="https://dashboard.mondayoff.me"
                target="_blank"
                rel="noreferrer"
              >
                Submit Your Game
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*mobile*/}
      <div className={styles.submittitle}>
        <h1>Submit Your Game</h1>
      </div>
      <div className={styles.mobilecardcontainer}>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.publishing_container}>
            <div className={styles.card}>
              <div className={styles.content}>
                <h1>Publishing</h1>

                <img
                  src={handShake}
                  className={styles.handShakeImg}
                  alt="handshake"
                />
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.partnership_container}>
            {/* <img
            src={fistBumpLeft}
            className={styles.fistBumpLeft}
            alt="fistbumpleft"
          />
          <img
            src={fistBumpRight}
            className={styles.fistBumpRight}
            alt="fistBumpRight"
          /> */}
            <div className={styles.card} id="card">
              <div className={styles.content}>
                <h1>Partnership</h1>

                <img src={powImg} className={styles.powImg} alt="powImg" />
              </div>
            </div>
          </div>
        </a>
      </div>
    </section>
  );
};

export default SubmitSection;
