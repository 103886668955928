import React from "react";
import styles from "../../articles.module.scss";
import basicsOfHCMain from "../../../../image/blog/writing/basicsOfHyperCasual/mainImg.png";
import basicsOfHCFirst from "../../../../image/blog/writing/basicsOfHyperCasual/firstImg.gif";
import basicsOfHCSecond from "../../../../image/blog/writing/basicsOfHyperCasual/secondImg.gif";
import basicsOfHCThird from "../../../../image/blog/writing/basicsOfHyperCasual/thirdImg.gif";
import aleIcon from "../../../../image/articles/Ale.png";
const BasicOfHC = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={basicsOfHCMain} alt="img" />
      <h1 className={styles.title}>Basics of Hyper-casual Game Creation</h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2022.02.03</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          If you are reading this, you are probably thinking about developing
          Hyper casual games. This article will cover what you need to know and
          do before creating your first Hyper-casual game and hopefully make a
          hit!
        </p>
        <h2>Develop your core game mechanic</h2>
        <p>
          The core gameplay is one of the essential characteristics of
          Hyper-casual. A good idea for those new developers is to create a
          runner; this is one of the most straightforward and fun to make. 
        </p>
        <p>
          <span>The top mechanics</span>
          <span> for runners are</span>
        </p>
        <ul>
          <li>Slide</li>
          <li>Drag</li>
          <li>Swipe</li>
          <li>Multiply</li>
          <li>Tap</li>
          <li>Stacking</li>
          <li>Growing</li>
        </ul>
        <div data-content="1">
          <img src={basicsOfHCFirst} alt="img" content="height_1" />
        </div>
        <p>
          Try to keep only one type of mechanic on your game so the player does
          not become overwhelmed. It is essential to choose a mechanic that you
          know it’s intuitive and easy for the player. 
        </p>
        <h2>Keep it Simple</h2>
        <p>
          When designing the game, you must keep it as simple as possible. Try
          not to get too excited with obstacles or add too much on the road,
          especially on a runner. In Hyper-casual, the players want to stay
          focused and feel rewarded when winning. If a game is too complicated,
          the player will most likely drop the game out of frustration. Keep
          your game simple, a bit challenging, fun, and engaging.
        </p>
        <h2> Make Your Main Character the Highlight</h2>
        <p>
          Just like keeping it simple, the player must focus on one object. By
          making the main character stand out more than the rest of the other
          objects, the player will know that this is the center of focus. You
          could have different aspects of the game, such as doors or items that
          could change your main character, for example, making it stronger,
          bigger, stacking things, or changing its clothes or overall image.
          Make sure that your character is visible and the player knows what to
          manage and what exactly they should do when looking at your main
          character.
        </p>
        <div data-content="1">
          <img src={basicsOfHCSecond} alt="img" content="height_1" />
        </div>
        <h2 className={styles.contentsFourthTitle}>Give Big Rewards</h2>
        <p>
          Our previous blog post 
          <a
            className={styles.link}
            href="https://mondayoff.me/How-ToHC/5"
            target="_blank"
            rel="noreferrer"
          >
            highlighted the importance of visual rewards
          </a>
          , which is a must when designing your first Hyper-casual game. You
          want the player to stay engaged and feel rewarded every time they
          finish a level. Always keep your player in the loop for more; this
          will increase your game retention and give you more chances of having
          a hit game. Rewards can also be obtained with your character design
          when it slightly changes or gets an object that immediately changes it
          for the better. 
        </p>
        <div data-content="1">
          <img src={basicsOfHCThird} alt="img" content="height_1" />
        </div>

        <p>
          When creating your first Hyper-casual game, no one expects you to make
          the perfect game. A good practice is to recreate these games and
          understand their mechanics and details. The key is to stay updated
          with the top charts and Hyper-casual trends and obtain inspiration
          from current games or create your original idea. 
        </p>
        <p>
          At MondayOFF, we have the tools to help you create an awesome
          Hyper-casual game. We care about those new to Hyper-casual
          development, and we give you tools and feedback for the development of
          your game. Contact us for more information. 
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default BasicOfHC;
