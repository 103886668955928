import top3MistakeMain from "../../image/blog/writing/top3mistake/top3mistakemain.png";
import topTrendMain from "../../image/blog/writing/toptrend/toptrendmain.png";
import howToKnowMain from "../../image/blog/writing/howtoknow/howtoknowmain.png";
import howToPlaceTop from "../../image/blog/writing/howtoplace/howtoplacemain.png";
import howToImportanceMain from "../../image/blog/writing/importance/mainimg.png";
import discoverMellowMain from "../../image/blog/writing/discovermellow/mainimg.png";
import MonLifeMain from "../../image/blog/writing/mondayoffLife/main.png";
import dunkunMainImg from "../../image/blog/writing/dunkun's Interview/mainimg.png";
import leslie from "../../image/blog/writing/profile/Leslie.png";
import ale from "../../image/blog/writing/profile/Alejandra.png";
import lenaIcon from "../../image/articles/Lena.png";
import blueIcon from "../../image/blog/writing/profile/blue.png";
import irisIcon from "../../image/articles/checkYourAdvertising/iris.png";
import denyIcon from "../../image/articles/whyDoGame/deny.png";
import christmasMain from "../../image/blog/writing/christmas/mainImg.png";
import basicsOfHCMain from "../../image/blog/writing/basicsOfHyperCasual/mainImg.png";
import successfulHCMain from "../../image/blog/writing/successfulHC/mainImg.png";
import HowToGenerate from "./HowToHC/HowToGenerate/HowToGenerate";
import BasicOfHC from "./HowToHC/BasicOfHC/BasicOfHC";
import MondayOffChristmasParty from "./MondayOffLife/MondayOffChristmasParty/MondayOffChristmasParty";
import DunkunInterview from "./MondayOffLife/DunkunInterview/DunkunInterview";
import FeelingTheChristmas from "./MondayOffLife/FeelingTheChristmas/FeelingTheChristmas";
import LifeOfMellow from "./OurGames/LifeOfMellow/LifeOfMellow";
import ImportanceOfVisualRewards from "./HowToHC/ImportanceOfVisualRewards/ImportanceOfVisualRewards";
import WhyInGame from "./HowToHC/WhyInGame/WhyInGame";
import AccordingToMetrics from "./MarketInsight/AccordingToMetrics/AccordingToMetrics";
import TopHCTrend from "./MarketInsight/TopHCTrend/TopHCTrend";
import TopMistake from "./HowToHC/TopMistake/TopMistake";
import TopIdleArcadeImg from "../../image/blog/writing/IdleArcade/topImg.png";
import IdleArcade from "./HowToHC/IdleArcade/IdleArcade";
import MRtopImg from "../../image/blog/writing/mondayRun/mondayRun.png";
import MondayRun from "./MondayOffLife/MondayRun/MondayRun";
import HybridCasual from "./HowToHC/HybridCasual/HybridCasual";
import hybridAricleThumnail from "../../image/articles/Article01.png";
import secondThumbnail from "../../image/articles/Article02.png";
import ImproveMetrics from "./MarketInsight/ImproveMetrics/ImproveMetrics";
import PracticalTips from "./MarketInsight/PracticalTips/PracticalTips";
import MethodTolowerCpi from "./MarketInsight/MethodTolowerCpi/MethodTolowerCpi";
import thirdThumbnail from "../../image/articles/Article03.png";
import methodThumbnail from "../../image/articles/MethodToLowerCpi/thumbnail.png";
import twoMillondownloadthumbnail from "../../image/articles/200m/thumbnail.png";
import checkYourAdvertisingThumbnail from "../../image/articles/checkYourAdvertising/img_blog_iris.png";
import kellyIcon from "../../image/articles/kelly.png";
import TwoMillon from "./OurGames/TwoMillon/TwoMillon";
import CheckYourAdvertising from "./MarketInsight/CheckYourAdvertising/CheckYourAdvertising";
import whyMobileGameThumbnail from "../../image/articles/whyDoGame/deny_thumbnail.png";
import WhyMobileGame from "./MarketInsight/WhyMobileGame/WhyMobileGame";
import introduceMondayOFFThumbnail from "../../image/articles/IntroduceMondayOFF/mobidictum_article_Thumb.png";
import IntroduceMondayOFF from "./MondayOffLife/IntroduceMondayOFF/IntroduceMondayOFF";
import CTAthumbnail from "../../image/articles/couldCTA/thumbnail.png";
import CouldCTA from "./MarketInsight/CouldCTA/CouldCTA";
export let article = [
  {
    id: 1,
    title: "Top 3 Mistakes Made by Hyper Casual Developers",
    tag: "How-To HC",
    topImg: top3MistakeMain,
    context:
      "We are humans, and that means that we make mistakes. As the Hyper casual industry quickly develops and the competition increases, it is crucial to know what to do and what NOT to do. This blog gives you the top 3 mistakes made by Hyper casual developers.",

    contents: <TopMistake />,

    name: "Alejandra Caceres",
    date: "2021.11.10",
    profileImg: ale,
  },
  {
    id: 2,
    title: "Top Hypercasual Trends of October and November",
    context:
      "In past October and November, the hyper-casual game industry has noticed the unfolding of new genres that have worked for the global community. Even though these game genres were not as prominent before, the audience has recently loved them. Not only these but mechanics and themes have steadily stayed in the charts, and multi mechanic options have become more popular on action and hyper-compacted games.",
    tag: "Market Insight",
    topImg: topTrendMain,

    contents: <TopHCTrend />,

    name: "Alejandra Caceres",
    date: "2021.11.12",
    profileImg: ale,
  },
  {
    id: 3,
    title: "How to Know Your Game is Doing Well According to Metrics",
    topImg: howToKnowMain,
    context:
      "As a Developer, when finishing up your prototype and preparing for a soft launch, it is crucial to understand the metrics involved in the testing stage. CPI, Retention, and eCPM are key metrics that show how the audience accepts your game and its potential for making it to the top charts. In this blog, you will learn to understand these metrics to know if your hyper-casual game is doing well in the market.",
    tag: "Market Insight",

    contents: <AccordingToMetrics />,

    name: "Alejandra Caceres",
    date: "2021.11.15",
    profileImg: ale,
  },
  {
    id: 4,
    title: "Why In-Game Advertise?",
    context:
      "Publishing a mobile game is not an easy task, especially when you don't have vast experience in monetization. In-game advertising is great to pump up revenue, get users engaged, and improve the Retention of your games.",
    tag: "How-To HC",
    topImg: howToPlaceTop,

    contents: <WhyInGame />,

    name: "Leslie Olesch",
    date: "2021.11.22",
    profileImg: leslie,
  },
  {
    id: 5,
    title: "The importance of visual rewards in Hyper-Casual",
    tag: "How-To HC",
    topImg: howToImportanceMain,
    context:
      "Hyper-casual games are straight to the point. With simple controls, short levels, and low difficulty, hyper-casual games offer what people nowadays enjoy the most: Instant gratification. There are many visual ways to grant players the feeling of accomplishment and satisfaction.",

    contents: <ImportanceOfVisualRewards />,

    name: "Leslie Olesch",
    date: "2021.11.25",
    profileImg: leslie,
  },
  {
    id: 6,
    title: "Discover life of mellow",
    topImg: discoverMellowMain,
    context:
      "MondayOFF is branching out! After many released games in the hyper-casual genre, we are ready to look for new challenges and areas to explore. With lots of hard work and the unlimited creativity of our development teams, we came out with a game that is entirely different from anything we have worked on before.",
    tag: "Our Games",
    contents: <LifeOfMellow />,

    name: "Leslie Olesch",
    date: "2021.11.30",
    profileImg: leslie,
  },
  {
    id: 7,
    title: "Feeling The Christmas Spirit at MondayOFF",
    tag: "MondayOFF Life",
    topImg: MonLifeMain,
    contents: <FeelingTheChristmas />,

    name: "Leslie Olesch",
    date: "2021.12.09",
    profileImg: leslie,
  },
  {
    id: 8,
    title: "MondayOFF On-Record: Meet Dunkun - MondayOff Co-Founder",
    context:
      "We want you to know us better. We are starting this new series of interviews to learn more about the history of MondayOFF and the people behind it. For our first interview, we decided to talk with Dunkun, the founder of MondayOFF. I hope you enjoy this brief but informative interview and know more about us.",
    tag: "MondayOFF Life",
    topImg: dunkunMainImg,
    contents: <DunkunInterview />,

    name: "Alejandra Caceres",
    date: "2021. 12. 16",
    profileImg: ale,
  },
  {
    id: 9,
    title: "A MondayOFF Christmas Party",
    context: "Ho Ho Ho! Christmas is Here at MondayOFF!",
    tag: "MondayOFF Life",
    topImg: christmasMain,
    contents: <MondayOffChristmasParty />,

    name: "Leslie Olesch",
    date: "2021.12.29",
    profileImg: leslie,
  },
  {
    id: 10,
    title: "Basics of Hyper-casual Game Creation",
    context:
      "If you are reading this, you are probably thinking about developing Hyper casual games. This article will cover what you need to know and do before creating your first Hyper-casual game and hopefully make a hit!",
    tag: "How-To HC",
    topImg: basicsOfHCMain,
    contents: <BasicOfHC />,

    name: "Alejandra Caceres",
    date: "2022.02.03",
    profileImg: ale,
  },
  {
    id: 11,
    title: "How to Generate Successful Hyper-casual Game Ideas",
    context:
      "When creating a new game, most of us become stuck in the first part, generating a successful game idea. It is essential to know how to have ideas that can be successful for your game creation. In this blog post, you will find out how to avoid a game idea block and emerge a successful one.",
    tag: "How-To HC",
    topImg: successfulHCMain,
    contents: <HowToGenerate />,

    name: "Alejandra Caceres",
    date: "2022.02.07",
    profileImg: ale,
  },
  {
    id: 12,
    title: "What makes a game Idle Arcade?",
    context:
      "Idle arcade games are impacting the Hyper casual market lately. Some of you might wonder, why is this game called idle arcade? What makes it different from other Hyper casual games? There are quite some differences, and in this blog post, you will find out the characteristics of arcade idle, its features, monetization, and its contrast with other Hyper casual games. ",
    tag: "How-To HC",
    topImg: TopIdleArcadeImg,
    contents: <IdleArcade />,

    name: "Alejandra Caceres",
    date: "2022.06.15",
    profileImg: ale,
  },
  {
    id: 13,
    title: "[Game Jam] Test as fast as you can - Monday Run",
    context: `The Hyper casual industry is ever-changing. One moment, you look at the current trends, and the next, everything has changed! To give our in-house studios a chance to take a break from daily routines and let them challenge their creativity without any restrictions, we held our first game jam,
    “Monday Run,” for two weeks, from June 8th to June 21st.`,
    tag: "MondayOFF Life",
    topImg: MRtopImg,
    contents: <MondayRun />,

    name: "Leslie Olesch",
    date: "2022.06.21",
    profileImg: leslie,
  },
  {
    id: 14,
    title: "What is Hybrid-Casual to MondayOFF?",
    context: `Hybrid-Casual has been creating quite a buzz recently. At MondayOFF, we believe that hybrid-casual is a natural progression from hyper-casual games.`,
    tag: "How-To HC",
    topImg: hybridAricleThumnail,
    contents: <HybridCasual />,

    name: "Alejandra Caceres",
    date: "2023.05.31",
    profileImg: ale,
  },
  {
    id: 15,
    title: "How to iterate your game to improve metrics",
    context: `If you have tested your game and it shows promising marketability with a low CPI, the next step is to iterate on it to improve its game data. `,
    tag: "Market Insight",
    topImg: secondThumbnail,
    contents: <ImproveMetrics />,
    name: "Alejandra Caceres",
    date: "2023.05.31",
    profileImg: ale,
  },
  {
    id: 16,
    title: "Practical Tips for Making Impactful Creatives",
    context: `In both hyper-casual and hybrid-casual categories alike, ad creatives play a major role.`,
    tag: "Market Insight",
    topImg: thirdThumbnail,
    contents: <PracticalTips />,
    name: "Lena",
    date: "2023.05.31",
    profileImg: lenaIcon,
  },
  {
    id: 17,
    title: "MondayOFF has reached 200 million downloads!",
    context: `MondayOFF was founded in 2018 as the first hyper-casual game developer in South Korea.`,
    tag: "Our Games",
    topImg: twoMillondownloadthumbnail,
    contents: <TwoMillon />,
    name: "Kelly",
    date: "2023.07.12",
    profileImg: kellyIcon,
  },
  {
    id: 18,
    title: "How To Reduce Your Game's CPI?",
    context: `We measure CPI (Click per Installation) through platforms.`,
    tag: "Market Insight",
    topImg: methodThumbnail,
    contents: <MethodTolowerCpi />,
    name: "Blue",
    date: "2023.07.14",
    profileImg: blueIcon,
  },
  {
    id: 19,
    title: "Check Your Advertising Videos First to Save Costs!",
    context: `When reviewing game ads, do you know which data is associated with game installation?`,
    tag: "Market Insight",
    topImg: checkYourAdvertisingThumbnail,
    contents: <CheckYourAdvertising />,
    name: "Iris",
    date: "2023.07.27",
    profileImg: irisIcon,
  },
  {
    id: 20,
    title: "Why do mobile game ads often show failed scenarios?",
    context: `Reasons why people create failure videos when advertising their games.`,
    tag: "Market Insight",
    topImg: whyMobileGameThumbnail,
    contents: <WhyMobileGame />,
    name: "Deny",
    date: "2023.08.04",
    profileImg: denyIcon,
  },
  {
    id: 21,
    title: "Introduction to MondayOFF ",
    context: `MondayOFF is a mobile game publisher specializing in hyper-casual and hybrid-casual games.`,
    tag: "MondayOFF Life",
    topImg: introduceMondayOFFThumbnail,
    contents: <IntroduceMondayOFF />,
    name: "Leslie",
    date: "2023. 08. 23",
    profileImg: leslie,
  },
  {
    id: 22,
    title: "Could CTA(call to actions) in the video lower the CPI?",
    context: `CTA stands for 'Call to Action,' a marketing term for design elements implemented to prompt an immediate response.`,
    tag: "Market Insight",
    topImg: CTAthumbnail,
    contents: <CouldCTA />,
    name: "Julia",
    date: "2023. 09. 08",
    profileImg: leslie,
  },
].reverse();
