import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/toptrend/firstinsertimg.png";
import second from "../../../../image/blog/writing/toptrend/secondinsertimg.png";
import topTrendMain from "../../../../image/blog/writing/toptrend/toptrendmain.png";
import aleIcon from "../../../../image/articles/Ale.png";
const TopHCTrend = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={topTrendMain} alt="thumbnail" />
      <h1 className={styles.title}>
        Top Hypercasual Trends of October and November
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021.11.12</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          In past October and November, the hyper-casual game industry has
          noticed the unfolding of new genres that have worked for the global
          community. Even though these game genres were not as prominent before,
          the audience has recently loved them. Not only these but mechanics and
          themes have steadily stayed in the charts, and multi mechanic options
          have become more popular on action and hyper-compacted games.
        </p>
        <h2>Classic Genres and Outbreaking New Ones for This Month</h2>
        <p>
          The genres that have always stayed steady for hyper-casual games are
          runner, simulation, and puzzle games. These genres never go out of
          trend since they are a classic in hyper-casual development and their
          ability to make so many different types of games within these
          sub-genres.
        </p>
        <div data-content="1">
          <img content="square_1" src={first} alt="" />
        </div>
        <p>
          In these past two months, hyper-compact (games that are a mixture of
          simulation with a story and many mini games within it), and action
          (high precision and focus on the gameplay) have increased in
          popularity. Especially, when it comes to games in which you can choose
          your own story. With the rise of the popularity of Squid Game, many
          action games climbed up the charts. It was incredible to see that one
          show could create such a big influence in popular culture and, at
          that, the gaming community.
        </p>
        <p>
          In the future, it is forecasted for hyper-compact games and themed
          games to develop even more. So, it is crucial to stay up to date with
          the trends in popular culture as a developer. Of course it is advised
          to stay alert with copyright.
        </p>
        <h2>Trending Control Mechanics and Upcoming Trends</h2>
        <p>
          It is a known rule that for hyper-casual games, simple and intuitive
          mechanics are a must. For this very reason, the most popular control
          mechanics that have stayed steady are slide, drag, tap, and hold. Not
          only that, but those controls mechanic stays the same for the whole
          game, the reason why we can call them hyper-casual.
        </p>
        <div data-content="1">
          <img content="square_1" src={second} alt="" />
        </div>
        <p>
          Nowadays, multi-control mechanics have increased in popularity. As
          seen in the chart, “multi options” is the most popular mechanic as of
          now. An example of multi options is a mixture of slide and tap or drag
          and tap. This type of mechanic is popular in hyper-compact and action
          games with multiple mini-games in them. Now in the hyper-casual
          industry, having a diverse of mini-games within one game is becoming a
          trend. We can see this with games such as Survival Games Challenge by
          Linqsoft Ltd., as the game progresses the controllers change depending
          on the mini game. This specific game is an example of the on going
          trend right now of hyper-compact and the use of pop culture
          references.
        </p>

        <h2>Top trendy Games and Uprising Ones</h2>
        <p>
          In the top charts, there are always those games that stay up in the
          top 20. Bridge Race by SUPERSONIC STUDIOS LTD, Arrow Fest by Rollic
          Games, and Water Sort Puzzle by IEC GLOBAL PTY LTD are a few of the
          games that whenever looking at the top charts are always there! These
          games have the most popular mechanics for sure and are very simple yet
          entertaining. Whenever a developer creates a game, their goal is for
          their game to stay in the top charts, such as these hyper-casual
          classics.
        </p>
        <p>
          In another way, the games Stone Miner by ZPlay, Flex Run by Voodoo,
          and Traffic Cop by Kwalee have stayed steadily in the top charts for
          these past two months. These types of games are not as common,
          especially Stone Miner and Traffic Cop. Hyper-compact games have
          become highly popular due to the number of mini-games within the game,
          making the player feel like they are playing multiple games in one
          while keeping the same theme and storyline. Meanwhile, Flex Run is a
          mixture between a runner and a puzzle game, making the player think
          quickly to have the correct poses at the right time.
        </p>
        <p>
          Action games with multiple mechanics are popular this season due to
          the success of “Squid Game” even after two months of its release,
          these games are still in the top charts. It makes publishers confirm
          that pop culture trends do make an impact on the gaming industry.
        </p>
        <p>
          In the future, it is expected for more hyper-compact games with
          multiple options to become more popular due to their recent success.
          Make sure to stay up with the popular culture and don’t be afraid to
          create something that is not within the usual, maybe you could create
          the next trend!
        </p>
        <p>
          Stay up to date with more information about the hyper-casual game
          industry by reading more articles on our blog or subscribing to our
          newsletter.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};
export default TopHCTrend;
