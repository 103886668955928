import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/articles/MethodToLowerCpi/first.gif";
import second from "../../../../image/articles/MethodToLowerCpi/second.gif";
import third from "../../../../image/articles/MethodToLowerCpi/third.gif";
import fourth from "../../../../image/articles/MethodToLowerCpi/fourth.gif";

import topTrendMain from "../../../../image/articles/MethodToLowerCpi/thumbnail.png";
import blueIcon from "../../../../image/blog/writing/profile/blue.png";
export default function MethodTolowerCpi() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={topTrendMain} alt="thumbnail" />
      <h1 className={styles.title}>How To Reduce Your Game's CPI?</h1>
      <div className={styles.profile} data-content="profile">
        <img src={blueIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Blue</span>
            <span>•</span>
            <span>Creative Team</span>
          </span>
          <span content="date">2023.07.14</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          We measure CPI (Click per Installation) through platforms. CPI refers
          to the cost incurred when users view an ad and install the game.
          Therefore, the ultimate goal is to create advertisements that induce
          many installations at a low cost. Lower CPI allows us to acquire more
          users with the same budget. In this regard, an ad with a CPI of $1.0
          or higher for hyper-casual games is considered unfavorable. At the
          same time, $0.7 to $0.5 indicates a video with the potential to lower
          the CPI, and below $0.5 is regarded as a good video. However, for
          various reasons, CPI may not decrease as desired. Find out why your
          CPI isn't decreasing and follow the tips below.
        </p>
        <h2>1. Excessive effects distract viewers.</h2>
        <p>
          To captivate viewers' interest, we often use editing techniques to
          highlight specific objects or increase the video's pace, making the ad
          more engaging. Simple edits can lower CPI, such as cut-transitions or
          adjusting the video speed. However, videos with complex editing, such
          as applying filters, can increase CPI. If the ad's primary purpose of
          showing the actual gameplay is ruined by too much editing, the
          original intent must be appropriately followed.
        </p>

        <div data-content="2">
          <div>
            <img content="height_2" src={first} alt="" />
            <p>Video with cartoon filter</p>
            <p>CPI: $1.5 ~ $2</p>
          </div>
          <div>
            <img content="height_2" src={second} alt="" />
            <p>Gameplay video without filter</p>
            <p>CPI: $0.4 ~ $0.6</p>
          </div>
        </div>
        <p>
          The left video is edited with a cartoon filter, while the right video
          showcases the in-game footage. Despite only applying a single filter
          in the left video, it results in a difference of over $1 in CPI
          compared to the right video. The significant distinction between the
          two videos lies in the design of the in-game objects. The pickaxe,
          protagonist, and collectible objects are visible in the right video,
          whereas the left video fails to show things adequately. Even with the
          duplicate footage, if the editing process alters the design, it can
          lead to a more than threefold difference in CPI. Therefore, preserving
          the original looks of game objects and maximizing their unique
          features is essential when editing videos.
        </p>

        <h2>
          2. Use of real-life footage leads to the unintended influence of user
          algorithms.
        </h2>
        <p>
          To capture viewers' attention, we sometimes utilize actual images or
          surreal video footage in the initial three seconds of the video.
          However, these videos can be a double-edged sword. While they can
          significantly reduce CPI when used appropriately, they can also
          drastically increase CPI.
        </p>
        <div data-content="2">
          <div>
            <img content="height_2" src={third} alt="" />
            <p>CPI: $0.8 ~ $1.2</p>
          </div>
          <div>
            <img content="height_2" src={fourth} alt="" />
            <p>CPI: $0.3 ~ $0.5</p>
          </div>
        </div>
        <p>
          The left video incorporates live-action footage, while the right video
          does not. Despite showcasing similar gameplay, the left video has
          approximately double the CPI compared to the right video. The reason
          for such results lies in how including live-action footage affects the
          algorithm through which the ad reaches users. As mentioned earlier,
          CPI represents the cost of ad exposure for a single installation. If
          including live-action footage unintentionally exposes the
          advertisement to many users who do not install the game, it only
          increases the ad cost. However, if real-life footage is used
          appropriately to drive installations, it can result in lower CPI.
          Thus, it is best to be careful while incorporating videos.
        </p>
        <h2>3. Irrelevant screenshots increase CPI</h2>
        <p>
          Creating well-made videos is not the only factor that contributes to
          the success of CPI. Evaluating the quality of the ad by considering
          the cost per click (CPC) is also crucial. A high-quality ad video is
          expected to generate a substantial number of clicks leading to
          installation. However, it is essential to ensure that these clicks
          translate into actual installations, which is why CPI is used to
          assess the game's market influence. Despite this, CPC still plays a
          significant role. If there is a significant difference between CPC and
          CPI, it indicates that users clicked on the ad but decided not to
          install the game after viewing the installation page's game icon or
          screenshots. In such cases, it is advisable to consider updating the
          screenshots or icons.
        </p>
        <h2>Conclusion</h2>
        <p>
          This article delves into three key factors that can aid in reducing
          CPI. Although CPI can vary drastically based on the type and style of
          gameplay, minimizing it as much as possible can prove advantageous.
          It's important to note that these three factors may vary for each
          game, so they should be carefully considered. By analyzing different
          data alongside these factors, it's possible to further decrease CPI.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
