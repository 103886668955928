import React from "react";
import styles from "../../articles.module.scss";
import secondThumbnail from "../../../../image/articles/Article02.png";
import one from "../../../../image/articles/howToITR/one.png";
import two from "../../../../image/articles/howToITR/two.png";
import three from "../../../../image/articles/howToITR/three.gif";
import four from "../../../../image/articles/howToITR/four.gif";
import five from "../../../../image/articles/howToITR/five.png";
import six from "../../../../image/articles/howToITR/six.png";
import aleIcon from "../../../../image/articles/Ale.png";

const ImproveMetrics = () => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={secondThumbnail} alt="thumnail" />
      <h1 className={styles.title}>
        How to iterate your game to improve metrics
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2023. 5. 31</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          If you have tested your game and it shows promising marketability with
          a low CPI, the next step is to iterate on it to improve its game data.
          Adding more content and implementing factors to increase the playtime
          of your game is crucial when making a new iteration. Many factors play
          into the current data of your game, and you must get an exact idea of
          what is influencing how users play your game.
        </p>
        <p>
          Here are some tips on how to improve your game data when iterating
          your game:
        </p>
        <h2>
          1. Improve your current KPI metrics by making data-based decisions.
        </h2>
        <div data-content="1">
          <img src={one} content="square_1" alt="img" />
        </div>
        <p>
          Utilize analytical tools to track not just playtime, session time, and
          user retention - when it comes to adequately iterating on your game,
          you should consider setting up monitoring events for the following:
        </p>
        <ul>
          <li>
            Level progression (start, fail, complete, drop off, progression
            funnel)
          </li>
          <li>Unlocking of areas and rewards. </li>
          <li>Button Clicks</li>
        </ul>
        <p>
          Testing your game with gameplay changes in your iteration gives you
          more insight into your game and enables you to improve your game
          metrics. A/B testing different level orders, difficulties, and game
          economies is an excellent way to find factors for improvement. Also,
          creating a level funnel will help you better understand how players
          react to your game and where they abandon it or try various times to
          pass a level.
        </p>

        <h2>2. Submit your iteration using new creatives.</h2>
        <div data-content="1">
          <img src={two} content="square_1" alt="img" />
        </div>
        <p>
          You are free to use the best-performing creative in your second
          iteration, but make sure that you test different characteristics that
          can allow you to improve your CPI. Using creatives you have not used
          before and re-using the same attributes in those previously tested
          creatives with the lowest CPI can help you further improve this
          metric. The reason for this is that a campaign cannot run off a single
          ad, and there also needs to be a way to target various players and see
          how these creatives affect the audience.
        </p>
        <p>Some tips for making good creative videos are:</p>
        <ul>
          <li>Keep it simple</li>
          <li>Use fail scenarios</li>
          <li>Use humor</li>
          <li>Challenge the viewers-"harder than you think," "IQ 500"</li>
        </ul>
        <p>
          In the case of MondayOFF's game Change and Drop, the creative team
          experimented with using different textures, colors, and objects for
          the ad videos. In one of the best performing creatives, the video on
          the left, shows the core gameplay with a light-colored background
          highlighting the balls and showing an increase in them when more are
          added as the video goes on. This was one of the best-performing
          creatives.
        </p>
        <p>
          In the other example, the balls were changed to popcorn, and the
          background color was blue. Unfortunately, this creative did not
          perform as well. It could be due to the popcorn not being as visible
          as the balls in the creative on the left side.
        </p>
        <div data-content="2">
          <img src={three} content="height_2" alt="img" />
          <img src={four} content="height_2" alt="img" />
        </div>
        <p>
          As seen in the example from Change and Drop, it is essential to
          highlight the main character and the core gameplay. Also, showing
          light colors that make the creative more visible and aesthetically
          pleasing is necessary.
        </p>
        <p>
          When making creatives for your next iterations, feel free to explore
          using different settings, colors, and backgrounds or even change the
          look of your main character. You never know what could work, and it
          will be helpful for you to understand what attracts users and be able
          to use this information on your next games.
        </p>
        <p>
          If you want more insights on making creatives, please read our blog
          post,{" "}
          <a
            className={styles.link}
            href="https://events.mondayoff.me/practical-tips"
            target="_blank"
            rel="noreferrer"
          >
            "Tips on making better creatives."
          </a>
        </p>
        <h2>
          3. Iterating your game while it is in Soft Launch or Commercial Launch
        </h2>
        <p>
          When your game is under a publishing contract, an ads SDK is
          immediately integrated to test your game's performance with ads and
          monetization during soft launch. After the ads SDK integration, there
          might be a significant decrease in your game's metrics by around 50%.
          This is a usual cause since players could get fatigued by the number
          of ads shown, so selecting the most optimal monetization strategies
          for your game is essential.
        </p>
        <p>
          During the soft launch, you can iterate your game several times to see
          which ad placements are best and if any meta-layers must be added to
          your game to increase engagement.
        </p>
        <p>
          During the soft launch, the KPI most important is the LTV. For this
          reason, developers must be ready to make more content to maintain a
          high retention and increase the overall LTV for your game.
        </p>
        <p>
          In MondayOFF’s{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=wacky%20jelly&c=apps"
            target="_blank"
            rel="noreferrer"
          >
            Wacky Jelly
          </a>
          , there was a significant increase in the LTV when the game was
          updated by adding more levels. The studio team realized that the game
          could obtain a much higher LTV if more levels were added. The update
          indeed increased the LTV by around 1.5 times. The graph below shows
          that the LTV from the original version of the game and the updated
          version increased a lot.
        </p>
        <div data-content="1">
          <img src={five} content="square_1" alt="img" />
        </div>
        <p>Wacky Jelly LTV 100 vs 300 levels.</p>
        <h2>
          4. Learn from your mistakes, and apply your experience in new
          projects.
        </h2>
        <div data-content="1">
          <img src={six} content="square_1" alt="img" />
        </div>
        <p>
          If you have done several iterations of your game and the data doesn't
          improve, it may be time to move on and test your skills with a
          different project. It is hard to give up on a project you have already
          started. Still, it is better to create something new that could be a
          success than be stuck on the same project in an endless loop -
          instead, you can try to use the good points of your previous project
          into elements that would also work in your new project. That could be
          an improved game economy, player rewards, button elements, and other
          assets. Moving into another project and considering what you have
          learned from analyzing your past game's data can help you understand
          what to avoid and which elements are crucial for making a better game
          with better metrics.
        </p>
        <p>
          When testing with{" "}
          <a
            className={styles.link}
            href="https://dashboard.mondayoff.me/"
            target="_blank"
            rel="noreferrer"
          >
            MondayOFF Dashboard
          </a>
          , you can compare your previous results and obtain some aid from
          publishing managers to help you improve your game. MondayOFF Dashboard
          allows you to test for free without limitations on submissions.
        </p>
        <p>
          At MondayOFF, we can provide you with helpful advice to improve your
          game and make new iterations to have better data.
        </p>
        <p>
          Check out our events page to learn more about our latest competition!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default ImproveMetrics;
