import { useEffect } from "react";

const ScrollTop = (props) => {
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, []);
  return null;
};

export default ScrollTop;
