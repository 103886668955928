import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/mondayoffLife/first.png";
import second from "../../../../image/blog/writing/mondayoffLife/second.png";
import third from "../../../../image/blog/writing/mondayoffLife/third.png";
import fourth from "../../../../image/blog/writing/mondayoffLife/fourth.png";
import MonLifeMain from "../../../../image/blog/writing/mondayoffLife/main.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const FeelingTheChristmas = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={MonLifeMain} alt="" />
      <h1 className={styles.title}>
        Feeling The Christmas Spirit at MondayOFF
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021. 12. 09</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <div data-content="1">
          <img content="width_1" src={first} alt="" />
        </div>
        <p>
          Christmas time is here, and the cozy, warm atmosphere has reached
          MondayOFF headquarters.
        </p>
        <p>
          As the days get colder, we wanted to create a comforting environment
          in our office. On MondayOFF day, which happens on the first Monday of
          every month, everyone bonds with their colleagues and unlocks
          creativity. This created just the right opportunity for us to turn our
          office into a Christmas paradise.{" "}
        </p>
        <p>
          Everyone actively participated in decorating various areas of the
          office. We set up a tree, created garlands, hung balloons, and adorned
          our plants with sparkly Christmas ornaments.
        </p>
        <div data-content="1">
          <img content="height_1" src={second} alt="" />
        </div>

        <p>
          But not only classic Christmas ornaments got to sparkle on our tree.
          Can you spot our games stealing the spotlight?
        </p>
        <p>
          We are grateful that everyone bonded during this playful day and
          participated so eagerly. Everybody will feel more comfortable working
          in this cozy space with this warm atmosphere.{" "}
        </p>
        <div data-content="1">
          <img content="height_1" src={third} alt="" />
        </div>

        <p>
          Now that everyone is in a Christmas mood, we are ready for our
          Christmas party happening later this month.{" "}
        </p>
        <p>
          We are so excited about all these events happening, and indeed they
          will keep us warm in the cold winter months.
        </p>
        <p>
          If you’re curious about MondayOFF life, follow us on LinkedIn for more
          updates.
        </p>
        <div data-content="1">
          <img content="height_1" src={fourth} alt="" />
        </div>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default FeelingTheChristmas;
