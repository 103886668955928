import React from "react";
import styles from "../../articles.module.scss";
import thumbnail from "../../../../image/articles/IntroduceMondayOFF/mobidictum_article_Thumb.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
export default function IntroduceMondayOFF() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thumbnail} alt="" />
      <h1 className={styles.title}>Introduction to MondayOFF</h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2023. 08. 23</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          MondayOFF is a mobile game publisher specializing in hyper-casual and
          hybrid-casual games. We focus on providing developers with flexible
          KPIs that enable them to publish their games. We are committed to
          providing detailed feedback, maintaining close communication with
          studios, and building strong relationships to achieve success.
        </p>
        <p>
          Our portfolio includes popular titles such as Gun Head Run and Life of
          Mellow, which have garnered over 200 million downloads. We are always
          looking for new games to add to our portfolio, so if you're a
          developer looking to publish your game, look no further than
          MondayOFF.
        </p>
        <h2>Our Values and Goals </h2>
        <p>
          At MondayOFF, we prioritize teamwork and strive to create a
          collaborative environment where everyone works together to achieve
          greatness.
        </p>

        <p>
          Our developers can take ownership of their projects and design
          enjoyable and profitable games with flexible KPIs.
        </p>

        <p>
          As our name implies, we want our users to take a break from their
          daily routines and enjoy a world of fun; the same goes for our
          employees. We actively seek partnerships with small studios to help
          promote their creations on a global scale.
        </p>
        <p>
          Our collaborative approach provides these talented studios with the
          resources and exposure they need to succeed, which enriches our
          portfolio with innovative and diverse games.
        </p>
        <h2>What to Expect When Working with MondayOFF?</h2>
        <p>
          At MondayOFF, we believe in providing game developers and studios with
          the best opportunities. That's why every game submitted to our
          dashboard undergoes a thorough review by our expert publishing
          managers. We actively encourage feedback requests from studios and
          individual developers for their next iteration. Contact us on
          <a
            href="https://discord.gg/Cf6smsvk7a"
            target="_blank"
            rel="noreferrer"
          >
            our Discord channel
          </a>
          to get in touch.
        </p>

        <p>
          Our flexible KPI approach allows us to provide opportunities to any
          game that showcases potential in metrics. If you prefer to iterate
          before requesting feedback, that's okay too. At MondayOFF, we work
          with your style to help you achieve your goals.
        </p>

        <p>
          Collaborating with us means close collaboration with one of our
          publishing managers, who will provide detailed feedback to improve
          metrics and pave the way for a global launch with access to millions
          of potential users. You'll also have access to our in-house developers
          to solve any problems you may encounter during the process and our
          creatives team for the best CPI.
        </p>

        <p>
          At MondayOFF, our team of highly skilled game artists and developers
          has excelled in game development since 2018. You can expect the same
          level of care and attention that we give to our in-house games for
          your game through our publishing managers. Join us today, and let's
          achieve success together.
        </p>

        <h2>MondayOFF Dashboard</h2>
        <p>
          Don't waste time waiting if you've got a prototype ready to test. You
          can begin submitting immediately through our dashboard without needing
          second-party reviews or approvals. Updating your game takes less than
          15 minutes once you're ready.
        </p>
        <p>
          At MondayOFF, we're always committed to providing our developers with
          the best tools and resources to help them succeed. Our dashboard is
          constantly evolving, with exciting new features on the way, such as
          productivity tools, including a simulation based on your test results
          that can project publishing benchmarks, giving you an idea of how
          successful your game can be if published. Regular updates ensure
          developers have access to cutting-edge tools that streamline game
          development and boost productivity. We encourage our developers to
          look forward to these upcoming enhancements dropping within 2023 as we
          strive to provide the tools they need to succeed in the fast-paced
          game development landscape.
        </p>

        <p>
          With our easy submission process and user-friendly dashboard, anyone
          can get started with us at the click of a button. If you have issues
          or questions regarding the submission process, join
          <a
            href="https://discord.gg/Cf6smsvk7a"
            target="_blank"
            rel="noreferrer"
          >
            our Discord
          </a>
          ; we will help you as quickly as possible. So come on board, everyone
          - at MondayOFF, we're here to help you thrive.
        </p>

        <h2>Everyone, MondayOFF!</h2>
        <p>
          Join MondayOFF today and take your mobile game to the next level. As a
          publisher, we prioritize teamwork, detailed feedback, and flexible
          KPIs to help you succeed. Our portfolio includes hit titles like Merge
          Bullet and Life of Mellow, and we're dedicated to expanding and
          growing with our developers. When you submit your game to our
          dashboard, you'll have access to our expert publishing managers,
          in-house developers, and creative team to ensure your game reaches its
          full potential. Take advantage of the benefits of partnering with
          MondayOFF - we look forward to working with you!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
