import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/howtoplace/firstimg.png";
import second from "../../../../image/blog/writing/howtoplace/secondimg.png";
import howToPlaceTop from "../../../../image/blog/writing/howtoplace/howtoplacemain.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const WhyInGame = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={howToPlaceTop} alt="thumbnail" />
      <h1 className={styles.title}>Why In-Game Advertise?</h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021. 11. 25</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          Publishing a mobile game is not an easy task, especially when you
          don't have vast experience in monetization. In-game advertising is
          great to pump up revenue, get users engaged, and improve the Retention
          of your games.
        </p>
        <h2>Types of In-Game Advertising</h2>
        <p>
          There are several types of in-game advertising (IGA). Dynamic ads are
          the most used in the hyper-casual genre. They allow targeting specific
          locations or groups of people, thus easy to implement and scale for
          most mobile games.
        </p>

        <h2>Banner Ads</h2>
        <p>
          Banner ads are the most simple and least invasive form of IGA. If
          integrated smoothly into game design, they do not disturb the user,
          but if misplaced, they may disturb the view and drastically reduce the
          player's experience.
        </p>

        <h2>Rewarded Ads</h2>
        <p>
          Rewarded ads are another simple way of getting the user to consume
          advertising content because they are rewarded for doing so. They will
          not negate the player's experience and increase Retention due to the
          gained resources. Despite this being a more costly way of advertising
          due to the use of resources from the publisher's side, increased
          Retention and a high eCPM will follow.
        </p>

        <h2>Interstitial Ads</h2>
        <p>
          These are one of the most common but possibly also one of the most
          complicated forms of IGA. Interstitials are full-screen ads usually
          implemented at a moment of transition, such as at the end of a
          completed stage.
        </p>
        <p>
          These days, interstitials display during pauses, at the end of a
          failed stage, and sometimes in the middle of the gameplay, making them
          a bold ad display method.
        </p>

        <h2>Why Interstitials?</h2>
        <p>
          In-game advertising has become a necessity for mobile game developers
          to generate enough profits for their hyper-casual games. In-app
          purchases aren't enough in a genre that depends on an
          easy-to-understand, easy-to-play concept. Surely, the more
          interstitials placed in the game, the higher the revenue.
        </p>
        <p>
          But won't aggressive use of interstitials disrupt the player
          experience and lower the eCPM? Won't longer intervals between ads
          improve Retention vs. short ones? Just what is the right balance
          between interstitials and gameplay that will boost eCPM and Retention?
        </p>
        <div data-content="1">
          <img content="height_1" src={first} alt="" />
        </div>

        <h2>How to find the correct intervals between Interstitials?</h2>
        <p>
          At MondayOFF, we are continuously looking for an answer, and while
          there is no one-fits-all formula that will generate you millions,
          there is a way to determine which timing is best suited for a game:
          A/B testing.
        </p>
        <p>
          We tested different intervals for many of our games, one of them being
          in our game “Shooting Color.” In “Shooting Color,” we tested
          Interstitials with intervals of 00:26, 00:23, and 00:20, and 00:17.
          With almost 10 seconds between the longest and shortest intervals, we
          expected a big difference in Retention, but the results were
          surprising. Both extremes showed only minor differences. Comparing the
          different intervals for the game, the D1 Retention for 00:26 was
          39.56%, and for 00:17, it was 39.12% - letting us conclude that
          shorter intervals can still make a game profitable.
        </p>
        <div data-content="1">
          <img content="square_1" src={second} alt="" />
        </div>
        <p>
          Of course, very short intervals between your interstitials can impact
          your funnel and Retention in the long term. By looking at the
          Intervals of 00:23 and 00:20, the D1 Retention for the former was
          39.39%, and for the latter, it was 39.18%. A drop in Retention and
          Funnel over the 00:17 D7 Retention concludes that the golden mean is
          the best way to find a balance between good Retention and good profit.
        </p>

        <h2>Where to start?</h2>
        <p>
          Naturally, in-game advertising should be planned from the beginning
          stages of development. Correct placement of banner ads and enticing
          rewards for players are necessary to encourage them to watch the
          advertisements. Still, even for interstitials, you should have a
          concept in mind to decide which approach to take.
        </p>
        <p>
          Despite all the planning, A/B-Testing is an easy way to test out which
          type of IGA your users like and which drives them away. Don't be too
          afraid of scaring away your players. In the end, what matters is
          having a fun game, and the better your game is, the more likely users
          will receive your interstitials positively. Chances are they enjoy the
          short break they get between the stages, and it will add to the
          excitement and expectations for the next level.
        </p>
        <p>
          If you would rather stay developing and let someone else take care of
          anything marketing for you, don't hesitate to contact us. We will take
          care of everything and be with you with every step, from early
          prototype testing to marketing and scaling your game.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default WhyInGame;
