import React from "react";
import styles from "./gamesection.module.css";
import be_A_Pong from "../../../../image/gameicon/Be_a_pong.jpg";
import gentle_Sniper from "../../../../image/gameicon/Gentle_Sniper.jpg";
import sneak_Out from "../../../../image/gameicon/Sneak_Out_3D.jpg";
import wacky_Jelly from "../../../../image/gameicon/Wacky_Jelly.jpg";
import tinyRun from "../../../../image/gamelist/tinyRun_home.jpg";
import appleStore from "../../../../image/appstoreicon/btn_appstore.png";
import googleStore from "../../../../image/appstoreicon/btn_googleplay.png";

import zigzag from "../../../../image/img_zigzag.png";
import { Link } from "react-router-dom";
const GameSection = (props) => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.zigzag}>
          <img src={zigzag} alt="zigzag" />
        </div>
        <h1 className={styles.title}>Explore Our Games</h1>
        <section className={styles.gamelist}>
          <div className={styles.game}>
            <img src={be_A_Pong} className={styles.game_icon} alt="be_A_Pong" />
            <h3 className={styles.gametitle}>Be a pong</h3>
            <h3 className={styles.download}>+10M Downloads</h3>
            <div className={styles.game_link}>
              <a
                href="https://apps.apple.com/app/id1463354791"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStore} alt="" className={styles.apple} />
              </a>
              <a
                href="http://play.google.com/store/apps/details?id=com.mondayoff.beerpong"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStore} alt="" className={styles.google} />
              </a>
            </div>
          </div>
          <div className={styles.game}>
            <img
              src={wacky_Jelly}
              className={styles.game_icon}
              alt="wacky_Jelly"
            />
            <h3 className={styles.gametitle}>Wacky Jelly</h3>
            <h3 className={styles.download}>+10M Downloads</h3>
            <div className={styles.game_link}>
              <a
                href="https://itunes.apple.com/app/id1566969578?mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStore} alt="" className={styles.apple} />
              </a>
              <a
                href="http://play.google.com/store/apps/details?id=com.mondayoff.jelly"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStore} alt="" className={styles.google} />
              </a>
            </div>
          </div>
          <div className={styles.game}>
            <img src={sneak_Out} className={styles.game_icon} alt="sneak_Out" />
            <h3 className={styles.gametitle}>Sneak Out 3D</h3>
            <h3 className={styles.download}>+10M Downloads</h3>
            <div className={styles.game_link}>
              <a
                href="https://itunes.apple.com/app/id1551261574?mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStore} alt="" className={styles.apple} />
              </a>
              <a
                href="http://play.google.com/store/apps/details?id=com.mondayoff.sneak"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStore} alt="" className={styles.google} />
              </a>
            </div>
          </div>
          <div className={styles.game}>
            <img
              src={gentle_Sniper}
              className={styles.game_icon}
              alt="gentle_Sniper"
            />
            <h3 className={styles.gametitle}>Gentle Sniper</h3>
            <h3 className={styles.download}>+5M Downloads</h3>
            <div className={styles.game_link}>
              <a
                href="https://itunes.apple.com/app/id1449222715?mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStore} alt="" className={styles.apple} />
              </a>
              <a
                href="http://play.google.com/store/apps/details?id=com.mondayoff.gentlesniper
"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStore} alt="" className={styles.google} />
              </a>
            </div>
          </div>

          <div className={styles.game}>
            <img src={tinyRun} className={styles.game_icon} alt="tinyRun" />
            <h3 className={styles.gametitle}>Tiny Run 3D</h3>
            <h3 className={styles.download}>+5M Downloads</h3>
            <div className={styles.game_link}>
              <a
                href="https://itunes.apple.com/app/id1565919001?mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appleStore} alt="" className={styles.apple} />
              </a>
              <a
                href="http://play.google.com/store/apps/details?id=com.mondayoff.tiny"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleStore} alt="" className={styles.google} />
              </a>
            </div>
          </div>
        </section>
      </section>
      <div className={styles.buttonarea}>
        <Link to="games" className={styles.button}>
          <div>View more games </div>
        </Link>
      </div>
    </>
  );
};

export default GameSection;
