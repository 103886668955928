import React, { useEffect } from "react";
import styles from "./videosection.module.css";
import background from "../../../backgroundvideo/intro_1.mp4";

const VideoSection = (props) => {
  useEffect(() => {
    let video = document.getElementsByClassName(styles.background);
    video[0].defaultMuted = true;
  }, []);
  return (
    <section className={styles.videosection}>
      <div>
        <div className={styles.text}>
          <div className={styles.title}>
            <p>Everyone,&nbsp;</p>
            <p> MondayOFF</p>
          </div>
        </div>
        <div className={styles.context}>
          <p>
            We create games that everyone can enjoy!{" "}
            <p>If you and I work together, we can do anything.</p>
          </p>
        </div>
      </div>

      <video
        className={styles.background}
        id="video"
        autoPlay
        muted
        loop
        webkit-playsinline="true"
        playsInline
      >
        <source src={background} className={styles.video} type="video/mp4" />
      </video>
    </section>
  );
};

export default VideoSection;
