import React from "react";
import styles from "../../articles.module.scss";
import successfulHCFirst from "../../../../image/blog/writing/successfulHC/firstImg.png";
import successfulHCSecond from "../../../../image/blog/writing/successfulHC/secondImg.png";
import successfulHCThird from "../../../../image/blog/writing/successfulHC/thirdImg.gif";
import successfulHCMain from "../../../../image/blog/writing/successfulHC/mainImg.png";
import aleIcon from "../../../../image/articles/Ale.png";
//2022.02.07
const HowToGenerate = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={successfulHCMain} alt="img" />
      <h1 className={styles.title}>
        How to Generate Successful Hyper-casual Game Ideas
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2022.02.07</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          When creating a new game, most of us become stuck in the first part,
          generating a successful game idea. It is essential to know how to have
          ideas that can be successful for your game creation. In this blog
          post, you will find out how to avoid a game idea block and emerge a
          successful one.
        </p>
        <div data-content="1">
          <img content="square_1" src={successfulHCFirst} alt="first" />
        </div>

        <h2>1. Brainstorm new ideas</h2>
        <p>
          Brainstorming might be the first step to any idea creation- this can
          lead to creating a new concept with more detail and knowing the pros
          and cons of every idea. You can generate a lot of information from
          brainstorming and create a detailed plan that can go into your
          Hyper-casual game.
        </p>
        <p>
            When brainstorming, make sure to write down anything that comes into
          your mind. No idea is a bad idea when creating a Hyper-casual game.
          Make sure to ask your coworkers, friends, or even family about any
          notion of a game that they would like to play. Also, it is favorable
          to obtain the opinion of others, as long as it doesn’t take away the
          main essence from your game. 
        </p>
        <div data-content="1">
          <img content="square_1" src={successfulHCSecond} alt="second" />
        </div>

        <h2 className={styles.secondTitle}>
          2. Keep up with Social Media Trends
        </h2>
        <p>
          As a hyper-casual developer, you must stay up to date with the social
          media trends, especially TikTok trends. TikTok is the most used and
          downloaded social media platform of the past year, and there’s a new
          trend in the app every month.
        </p>
        <p>
          When checking TikTok, take a look at the trending hashtags. These will
          give you information about the current trends and most popular songs.
          For example, right now some trends are{" "}
          <span style={{ textDecoration: "underline" }}>
            “Euphoria” inspired make-up looks
          </span>{" "}
          and{" "}
          <span style={{ textDecoration: "underline" }}>
            drawing your face in an outline
          </span>
          .
        </p>
        <p>
          Don’t be afraid to explore other social media sites for more insight
          into what’s trending. You can use the information obtained from these
          social media sites to make your hyper-casual game more relatable to
          everyone. 
        </p>
        <div data-content="1">
          <img content="square_1" src={successfulHCThird} alt="third" />
        </div>

        <h2>3. Check Game Top Charts</h2>
        <p>
          If you’re into hyper-casual games, you have to know what games are in
          the top charts. Learning this information will give you an idea of
          what people currently like, and which mechanics and designs have
          succeeded recently. Also, by staying up to date with the charts, you
          can obtain game ideas for future inspiration. It is hard to beat the
          classic themes, but you can always create your twist on them to make
          them more engaging.
        </p>
        <p>
          You can make your game idea by mixing trending game themes and using a
          mechanic that is proven to work. Remember to keep your game ideas
          creative yet straightforward and original.
        </p>
        <h2>4. Last but Not Least- Don’t Stress!</h2>
        <p>
          Just because ideas are not directly flowing into your brain, it does
          not mean that you can’t have a good game idea or that you are not
          creative enough. Make sure to relax and not force out a picture out of
          nowhere. Please do some research and consult with others. The idea you
          are looking for might come out the least when you expect it.
        </p>
        <p> So relax and write down any idea that might come up!</p>
        <p>
          At MondayOFF, we can help you generate fantastic game ideas! You can
          brainstorm and obtain valuable feedback with the help of our
          publishing managers to further improve your game-making process. 
        </p>
        <p>Make sure to submit your game with us to obtain our services! </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default HowToGenerate;
