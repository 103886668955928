import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/IdleArcade/first.png";
import second from "../../../../image/blog/writing/IdleArcade/second.gif";
import third from "../../../../image/blog/writing/IdleArcade/third.jpeg";
import TopIdleArcadeImg from "../../../../image/blog/writing/IdleArcade/topImg.png";
import aleIcon from "../../../../image/articles/Ale.png";
const IdleArcade = () => {
  return (
    <article className={styles.container}>
      <img
        src={TopIdleArcadeImg}
        alt="thumbnail"
        className={styles.thumbnail}
      />
      <h1 className={styles.title}>What makes a game Idle Arcade?</h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2022. 6. 15</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          Idle arcade games are impacting the Hyper casual market lately. Some
          of you might wonder, why is this game called idle arcade? What makes
          it different from other Hyper casual games? There are quite some
          differences, and in this blog post, you will find out the
          characteristics of arcade idle, its features, monetization, and its
          contrast with other Hyper casual games.{" "}
        </p>
        <h2>Why is idle arcade different from other Hyper casual games?</h2>
        <p>
          As known before, the ideal Hyper casual game has a CPI of at least
          $0.35 and a RET of over 25%. Regarding idle arcade, the CPI is higher,
          the CPI could be about $0.50, and the RET is over 40%. In an idle
          arcade game, the gameplay focuses on engaging the player for a long
          time and not abandoning the game for a while, making the average
          playtime over 1200 seconds. These factors make the LTV higher and the
          final profit of the game even higher.
        </p>
        <div data-content="1">
          <img content="square_1" src={first} alt="img" />
        </div>

        <h2>Characteristics of idle arcade</h2>
        <p>
          Now, what makes an idle arcade game? Developers must take many
          characteristics into account for idle arcade games to have an impact
          on the player. When developing an idle arcade game, you must have
          satisfying gameplay, for example, collecting enormous amounts of money
          and carrying it to another place.{" "}
        </p>
        <p>
          Another characteristic is giving the player a familiar scenario. These
          scenarios can vary, but they must follow a pattern in which the player
          can connect their actions to develop the map even more, making it the
          game's objective. For the map development, there are various options,
          the usual ones free roaming and level-based. For free roaming, the
          player can go around the map however they wish and keep expanding it
          with no stop. For level-based maps, once the player finishes an
          essential task, they will go onto the next level, where they will need
          to restart the construction again.
        </p>
        <p>
          The game's main character is primarily simple, looking like a jelly
          man or stick man.{" "}
        </p>
        <p>
          When it comes to the camera view, developers must place the camera on
          a slanted top view, allowing the player to see more of the map and
          surroundings, giving the player more ability to view all of the
          actions occurring and know where exactly to go.{" "}
        </p>
        <p>
          For game mechanics, idle arcade almost always uses the joystick
          controls. This is to have the player move easier around the map since
          they play a dynamic character in the game.
        </p>
        <div data-content="1">
          <img content="height_1" src={second} alt="img" />
        </div>
        <p>
          My Little Universe by SayGames is a great example of a successful
          level-based Idle Arcade game.{" "}
        </p>

        <h2>Idle arcade monetization, how to deal with it?</h2>
        <p>
          Since idle arcade keeps the player engaged for a long time, there are
          many game monetization opportunities. The main point of idle arcade is
          world expansion. For the world to keep expanding, there will need to
          be transactions to unlock the new areas. As the world keeps
          developing, the items become more expensive, challenging the player to
          spend more on creating new sites for the flow to go faster and
          smoother. When the player starts needing game upgrades is when
          monetization can take place.
        </p>
        <p>
          Most of the time, idle arcade games include Reward Videos (RVs) for
          the player to obtain new items or boost their character to go faster.
          RVs can play in between levels or have a merchant on the map in which
          the player can watch an RV to obtain a boost to their gameplay.
        </p>
        <p>
          When it comes to placing Interstitials (IS), this can be a bit more
          tricky to implement. Since the main point of an idle arcade game is to
          keep the player engaged, IS can make the player want to quit the game
          if they are played too often. The ideal placement would be in between
          levels if it is level-based. Regarding roam-free maps, there could be
          a particular time on when to place the IS. Knowing the exact time to
          set the IS can affect your gameplay and monetization for your game.{" "}
        </p>
        <p>
          In App Purchases (IAP) for idle arcade games are more doable since the
          players are engaged for a long time. IAP must be placed as the game
          develops and the player needs more help on their map expansion. These
          IAP could be permanent boosts or characteristics to make the gameplay
          easier for the player. They could also substitute RVs. There could
          also be IAP to quit IS in a free roam map.
        </p>
        <div data-content="1">
          <img content="height_1" src={third} alt="img" />
        </div>

        <p>
          A good example of IAP is in My Little Universe. The player has the
          option to pay $2.99 to skip ads and gain extra resources.
        </p>

        <h2>Are you ready for your idle arcade game to be a hit?</h2>
        <p>
          For your idle arcade game to be a hit, make sure that you implement
          all the needed characteristics and make it engaging and satisfying for
          the player. Players need to be constantly engaged, and for that, the
          gameplay should be easy for the player to understand right away but
          have its challenging parts, so not everything stays the same.
        </p>
        <p>
          I hope these tips and information help you create the next idle arcade
          hit! At MondayOFF, we provide feedback and all the necessary
          information for your game to be a hit. If you wish to test your games
          with us, access our self-served MondayOFF Dashboard, your campaign
          will start right after your submission!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default IdleArcade;
