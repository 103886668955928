import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/importance/firstimg.png";
import second from "../../../../image/blog/writing/importance/secondimg.png";
import third from "../../../../image/blog/writing/importance/thirdimg.png";
import howToImportanceMain from "../../../../image/blog/writing/importance/mainimg.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const ImportanceOfVisualRewards = (props) => {
  return (
    <article className={styles.container}>
      <img
        src={howToImportanceMain}
        alt="thumnail"
        className={styles.thumbnail}
      />
      <h1 className={styles.title}>
        The importance of visual rewards in Hyper-Casual
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021. 11. 25</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          Hyper-casual games are straight to the point. With simple controls,
          short levels, and low difficulty, hyper-casual games offer what people
          nowadays enjoy the most: Instant gratification. There are many visual
          ways to grant players the feeling of accomplishment and satisfaction.
        </p>
        <p>
          In-game, this usually shows in the form of change. Objects you collect
          will stack, multiply, or grow in size. A character can change and
          transform depending on the item it just came in contact with. All
          these instant changes offer satisfaction and have a huge impact on
          CTR, CPI, and RET. But there is one more thing that will give your
          game the boost it needs to make it to the charts - visual rewards.
        </p>

        <h2>Why visual rewards?</h2>
        <p>
          Visual rewards are used in plenty of ways. They may come in the form
          of a sequence at the end of a completed level (think treasure chests
          or characters unlocking), text (Great! Amazing!), graphics (drastic
          change in gameplay such as special effects, a fever phase, special
          reward levels) and also in the form of sound.
        </p>
        <p>
          The reason why visual rewards are pretty much a must-have these days
          is simple. The competition is high, and players want to feel
          satisfied. A game that makes players feel accomplished and encourages
          them to keep playing will naturally result in higher Retention.
        </p>

        <div data-content="1">
          <img content="height_1" src={first} alt="img" />
        </div>

        <h2>What other effects do visual rewards have?</h2>
        <p>
          Visual rewards definitely play a role in the Creatives process as
          well. The first three seconds are crucial to draw a player in and make
          him understand what exactly the game is about. But now you caught his
          attention, you also have to keep it. Generally, the consensus is that
          a creative should have something exciting to show every 3 seconds.
          While gameplay and design are of the highest importance, visual
          rewards play a big role in making the game look more rewarding and
          satisfying. Confetti, coins, or Well Done! flying across the screen -
          all of these will draw a player in, promising a very satisfactory
          game.
        </p>
        <div data-content="1">
          <img content="height_1" src={second} alt="img" />
        </div>
        <p>
          It isn’t always easy to find the right way to implement visual
          rewards. Sometimes, they might overwhelm the player or even cover
          important events on the screen. To get a feel for visual rewards,
          check out games of similar genre and gameplay. The competition most
          likely found some great ways to deal with visual rewards, and it is
          never a bad idea to get some inspiration from already successful
          cases. Always start out simple. Don’t overload the player with
          information, and instead give them a sense of accomplishment that they
          will keep seeking. Out of the many types of visual rewards, there will
          be one that matches your game perfectly.
        </p>
        <div data-content="1">
          <img content="height_1" src={third} alt="img" />
        </div>

        <p>
          If you would like some feedback on your game or are unsure about
          visual rewards, don’t hesitate to contact us.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default ImportanceOfVisualRewards;
