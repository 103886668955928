import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/blog/writing/discovermellow/firstimg.png";
import second from "../../../../image/blog/writing/discovermellow/secondimg.png";
import third from "../../../../image/blog/writing/discovermellow/thirdimg.png";
import discoverMellowLink from "../../../../image/blog/writing/discovermellow/linkimg.png";
import appleImg from "../../../../image/appstoreicon/iosBtn.png";
import googleImg from "../../../../image/appstoreicon/aosBtn.png";
import discoverMellowMain from "../../../../image/blog/writing/discovermellow/mainimg.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const LifeOfMellow = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={discoverMellowMain} alt="" />
      <h1 className={styles.title}>Discover life of mellow</h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2023. 08. 23</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          MondayOFF is branching out! After many released games in the
          hyper-casual genre, we are ready to look for new challenges and areas
          to explore. With lots of hard work and the unlimited creativity of our
          development teams, we came out with a game that is entirely different
          from anything we have worked on before.
        </p>
        <div data-content="1">
          <img content="width_1" src={first} alt="" />
        </div>

        <p>
          With our newest game, Life of Mellow, we have ventured into the
          Strategy/Simulation genre for the first time. The idea is clear: Build
          a Mellow village, let more Mellows join you, and expand as much as you
          can. Of course, to make all this possible, the Mellows have to have
          their basic needs met: They need housing, food, and happy life to work
          hard on their village expansion.
        </p>
        <div data-content="1">
          <img content="height_1" src={second} alt="" />
        </div>

        <p>
          There are many things to do at the Mellow Village. You can mine, farm,
          gather, craft, and set up buildings such as schools and churches to
          keep your Mellows happy and safe. It might sound peaceful and
          relaxing, but don't be fooled by these cute marshmallow-looking
          creatures walking about the village - Managing your resources and
          taking care of the Mellows' wellbeing is certainly not an easy task!
          With winter's biting cold just around the corner and infectious
          viruses sneaking in, it won't be easy to survive all the dangers
          lingering in the dark.
        </p>
        <div data-content="1">
          <img content="height_1" src={third} alt="" />
        </div>

        <p>
          Micromanage your way to the top - from 3 Mellows to 15, to 50! How
          many seasons will you survive? Only the sky is the limit in the
          adorable world of Mellows. Check it out now!
        </p>
        <div data-content="gameLinkBox">
          <img
            content="game_square_1"
            src={discoverMellowLink}
            alt=""
            className={styles.linkimg}
          />
          <p>Life of Mellow</p>
          <div className={styles.game_link}>
            <a
              content="ios"
              href="https://apps.apple.com/us/app/stack-cube/id1578810061"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appleImg} alt="" />
            </a>
            <a
              content="aos"
              href="https://play.google.com/store/apps/details?id=com.mondayoff.mellow"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleImg} alt="" />
            </a>
          </div>
        </div>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default LifeOfMellow;
