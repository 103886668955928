import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/articles/couldCTA/1.png";
import second from "../../../../image/articles/couldCTA/2.png";
import third from "../../../../image/articles/couldCTA/3.png";
import fourth from "../../../../image/articles/couldCTA/4.png";
import fifth from "../../../../image/articles/couldCTA/5.png";
import thumbNail from "../../../../image/articles/couldCTA/thumbnail.png";
import irisIcon from "../../../../image/articles/checkYourAdvertising/iris.png";
export default function CouldCTA() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thumbNail} alt="thumbnail" />
      <h1 className={styles.title}>
        Could CTA(call to actions) in the video lower the CPI?
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={irisIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Julia</span>
            <span>•</span>
            <span>Creative Team</span>
          </span>
          <span content="date">2023. 09. 07</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          CTA stands for 'Call to Action,' a marketing term for design elements
          implemented to prompt an immediate response. It may initially sound
          unfamiliar, but CTAs are widely used in various advertisements, and we
          come across them often without even realizing it. Buttons like 'Buy
          Now, Order Now, Learn More' on ads and banners found on social media
          platforms and portal sites are some of the most common examples of
          CTAs. Now, can we apply them to CPI videos? Absolutely. As shown
          below, many publishers have already incorporated CTA elements into
          their videos.
        </p>
        <div data-content="1">
          <img src={first} alt="" content="width_1" />
        </div>
        <p>
          The examples above show that there are various ways in which CTAs can
          be implemented. For instance, button-type CTAs are placed at the
          bottom of the screen with prompts such as "Play Now," similar to the
          two left images. Another type involves Playable ads, which allow users
          to directly engage with the game by encouraging touch interactions, as
          shown in the pictures on the right. So, can these two types of CTAs
          help reduce CPI? In conclusion, yes, they can!
        </p>
        <p>
          The creative team at MondayOff conducted a test to find answers to the
          previous question. To conduct the test, we divided the CPI into four
          ranks (A-Rank $0.10- $0.50 / B-Rank $0.50-$0.70 / C-Rank $0.70-$0.90 /
          D-Rank $0.90 and above). We could rank our games' creatives by
          selecting the most representative, low CPI creatives for each game.
          The results are as follows.
        </p>
        <div data-content="1">
          <img src={second} alt="" content="width_1" />
        </div>
        <p>
          In addition to the basic CPI videos, we also created videos with CTAs.
          These videos can be categorized into two types. For convenience, we'll
          refer to the videos with added buttons leading to the installation
          page as CTA1 and the videos that encourage screen touches, mimicking
          playable ads, as CTA2. For the selected 8 games in the groups above,
          we produced 24 advertisements, including three videos for each game:
          the basic CPI video showing gameplay, CTA1, and CTA2.
        </p>

        <div data-content="2">
          <img src={third} alt="" content="height_2" />
          <img src={fourth} alt="" content="height_2" />
        </div>
        <p>
          After calculating the average CPI values for the original CPI videos
          without CTAs, the CTA1 and CTA2 videos, and comparing them, it was
          observed that CTA1 resulted in approximately a 6% decrease in CPI. In
          comparison, CTA2 led to a roughly 2% decrease. While the reduction
          rates may not seem significantly high at first glance, it's worth
          noting that when comparing the CPI for each video rather than the
          average, the reduction could go as high as 27% in some cases.
        </p>
        <p>
          However, it's essential to understand that these results alone don't
          conclusively determine whether the CTA1 approach is universally
          better. This is just an average based on a small sample group, and the
          effectiveness of CTA1 or CTA2 can vary depending on the type of game.
          Therefore, testing both approaches is advisable to see which works
          best for each game.
        </p>
        <div data-content="1">
          <img src={fifth} alt="" content="height_1" />
        </div>
        <p>
          It's important to emphasize that CTA doesn't always guarantee a
          reduction in CPI. Like the example above, imagine a scenario with
          buttons in the CPI video. While some viewers might tap the faux
          buttons out of curiosity, the probability of them installing the game
          after being redirected to the app store without intending to is low.
          The role of CTA is to encourage clicks, but more is needed to
          guarantee conversions. For CPI videos with CTAs to be effective, it's
          crucial to have engaging gameplay content in the CPI video itself. If
          you need help creating compelling CPI videos, consider referring to
          our previous articles for guidance!
        </p>
        <ul>
          <li>
            <a
              href="https://mondayoff.me/MarketInsight/18"
              target="_blank"
              rel="noreferrer"
            >
              How To Reduce Your Game's CPI?
            </a>
          </li>
          <li>
            <a
              href="https://mondayoff.me/MarketInsight/19"
              target="_blank"
              rel="noreferrer"
            >
              Check Your Advertising Videos First to Save Costs!
            </a>
          </li>
          <li>
            <a
              href="https://mondayoff.me/MarketInsight/20"
              target="_blank"
              rel="noreferrer"
            >
              Why do mobile game ads often show failed scenarios?
            </a>
          </li>
        </ul>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
