import React from "react";
import { Link } from "react-router-dom";
import styles from "./header.module.css";
import logo from "../../image/mondayoff_logo_03.png";

const Header = (props) => {
  const menuList = document.getElementsByClassName(styles.menulist);
  const logoImg = document.getElementsByClassName(styles.logo);
  const onClick = (e) => {
    menuList[0].classList.toggle(styles.active);
    logoImg[0].classList.toggle(styles.active);
  };

  return (
    <header className={styles.nav}>
      <Link to="/">
        <img src={logo} className={styles.logo} alt="mondayoff" />
      </Link>

      <ul className={styles.menulist}>
        {/* <li className={styles.menu}>
          <Link to="/">Home</Link>
        </li> */}
        {/* <li className={styles.aboutmenu}>
          <Link onClick={scrollByAbout} className={styles.abouttext}>
            About
          </Link>
        </li> */}
        <li className={styles.gamemenu}>
          <Link to="/games" className={styles.gametext}>
            Games
          </Link>
        </li>
        <li className={styles.publishmenu}>
          <Link to="/publish" className={styles.publishtext}>
            Publishing
          </Link>
        </li>
        <li className={styles.partnershipmenu}>
          <Link to="/partnership" className={styles.partnershiptext}>
            Partnership
          </Link>
        </li>
        <li className={styles.articlemenu}>
          <a
            className={styles.articletext}
            href="https://blog.mondayoff.me"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
        </li>
        {/* <li className={styles.menu}>
          <Link>Contect</Link>
        </li> */}
      </ul>
      <div className={styles.container}>
        <input className={styles.check} type="checkbox" id="burger-check" />
        <label
          className={styles.icon}
          id="icon"
          htmlFor="burger-check"
          onClick={onClick}
        >
          <span className={styles.sticks}></span>
        </label>
      </div>
    </header>
  );
};

export default Header;
