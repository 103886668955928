import React from "react";
import styles from "../../articles.module.scss";
import first from "../../../../image/articles/whyDoGame/Short_GunHead_A.gif";
import second from "../../../../image/articles/whyDoGame/Short_GunHead_B.gif";
import third from "../../../../image/articles/whyDoGame/Short_SavetheGrandmother_C.gif";
import fourth from "../../../../image/articles/whyDoGame/Short_SavetheGrandmother_D.gif";
import fifth from "../../../../image/articles/whyDoGame/Short_WantedFish_E.gif";
import sixth from "../../../../image/articles/whyDoGame/Short_WantedFish_F.gif";
import thumbNail from "../../../../image/articles/whyDoGame/deny_thumbnail.png";
import denyIcon from "../../../../image/articles/whyDoGame/deny.png";
export default function WhyMobileGame() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thumbNail} alt="thumbnail" />
      <h1 className={styles.title}>
        Why do mobile game ads often show failed scenarios?
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={denyIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Deny</span>
            <span>•</span>
            <span>Creative Team</span>
          </span>
          <span content="date">2023.08.04</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <h2>
          Reasons why people create failure videos when advertising their games.
        </h2>
        <p>
          When watching hyper-casual game advertisements, intentional scenes of
          failure are often included. While this may be frustrating for viewers,
          there are reasons why these ads are created. Our research has shown
          that these videos achieve better performance (lower CPI) compared to
          typical gameplay videos. In this article, we will explore the reasons
          behind the success of these videos through three game examples.
        </p>
        <h2>Gun Head Run</h2>
        <div data-content="2">
          <img content="height_2" src={first} alt="" />
          <img content="height_2" src={second} alt="" />
        </div>
        <p>
          Gun Head Run is a game where players run forward, collecting weapons
          and flags to progress further. The advertisements for the game can be
          divided into two types. The first type of ad (A) shows the character
          collecting all the items while defeating all obstacles and running
          forward. This gameplay gives viewers a sense of achievement and
          growth. In contrast, the second type of ad (B) intentionally avoids
          picking up items and runs towards random places, leading to failure.
        </p>
        <p>
          Surprisingly, the (B) ad, which showed a frustrating and unsatisfying
          gameplay, had a significantly lower CPI than the (A) ad. In runner
          game advertisements, presenting moments of failure and disappointment
          tend to attract more installations because it ignites the users'
          desire to challenge themselves.
        </p>
        <h2>Save the Grandmother</h2>
        <div data-content="2">
          <img content="height_2" src={third} alt="" />
          <img content="height_2" src={fourth} alt="" />
        </div>
        <p>
          Save the Grandmother is a game where players protect a grandmother
          from oncoming cars by swiping to move her out of harm's way. Among
          various types of advertisements for this game, the most successful one
          was a video that showed the player almost saving the grandmother but
          failing by a second. Interestingly, the video that showed failure (D)
          had an approximately 55% decrease in CPI compared to the one where the
          grandmother was perfectly protected (C) in many tests.
        </p>
        <h2>Wanted Fish</h2>
        <div data-content="2">
          <img content="height_2" src={fifth} alt="" />
          <img content="height_2" src={sixth} alt="" />
        </div>
        <p>
          Finally, Wanted Fish is a game where players catch fish by dragging to
          aim and shoot. Despite creating various types of advertisements for
          the game, two videos stood out with contrasting performance. The first
          video (E) shows an immediate success in catching fish with the
          harpoon. While it provides a sense of achievement, the immersion and
          anticipation for the hunting experience are short-lived. On the other
          hand, the second video (F) builds up excitement and tension by aiming
          for a long time, giving the impression that the catch will be
          successful, but eventually ends in failure, leaving viewers feeling
          unsatisfied. This had seemed to create motivation for installation,
          and as a result, the (F) video achieved a 60% decrease in CPI compared
          to the (E) video.
        </p>
        <p>
          In summary, analyzing the three games mentioned above, deliberately
          not using a good weapon, failing to save the grandmother, and
          continuously missing fish during the gameplay used to be crucial
          elements that determined the success of the advertisement videos in
          numerous cases. In hyper-casual game advertisements, showing moments
          of failure can often stimulate users' feelings of disappointment,
          desire to participate, and the urge to take on challenges, leading to
          better performance.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
