/*EN-Ver*/
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Route,
  RouterProvider,
  Routes,
  Switch,
} from "react-router-dom";
import { useEffect } from "react";
import styles from "./App.module.css";
import Header from "./components/header/header";
import Home from "./components/main/home/home";
import Game from "./components/main/game/game";
import Blog from "./components/main/blog/blog";
import PublishingSubmit from "./components/main/publishingsubmit/publishingsubmit";
import PartnershipSubmit from "./components/main/partnershipsubmit/partnershipsubmit";
import Footer from "./components/footer/footer";
import PublishPage from "./components/publishpage/publishpage";
import PartnershipPage from "./components/partnershippage/partnershippage";
import Writing from "./components/main/blog/writing/writing";
import Notfound from "./error/Notfound";
import getGA from "./service/ga.js";
function App() {
  useEffect(() => {
    getGA();
  }, []);
  useEffect(() => {
    if (window.location.pathname === "/privacyPolicy") {
      window.location.pathname = "/privacyPolicy.html";
    }
  });

  return (
    <>
      <div className={styles.App}>
        <Header />
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/games" component={Game} />
          <Route path="/publish" component={PublishPage} />
          <Route path="/partnership" component={PartnershipPage} />
          <Route path="/publishingsubmit" component={PublishingSubmit} />
          <Route path="/partnershipsubmit" component={PartnershipSubmit} />
          {/* <Route path="/blog" component={Blog} />
          <Route path="/:tagname/:id" component={Writing} /> */}
          <Route path="*" component={Notfound} />
        </Switch>
        <Footer />
      </div>
    </>
  );
}

export default App;
