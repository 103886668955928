import React from "react";
import styles from "../../articles.module.scss";
import poster from "../../../../image/blog/writing/mondayRun/mondayRunPoster.png";
import firstGif from "../../../../image/blog/writing/mondayRun/IC.gif";
import secondGif from "../../../../image/blog/writing/mondayRun/GHR.gif";
import MRtopImg from "../../../../image/blog/writing/mondayRun/mondayRun.png";
import leslie from "../../../../image/blog/writing/profile/Leslie.png";
const MondayRun = () => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={MRtopImg} alt="" />
      <h1 className={styles.title}>
        [Game Jam] Test as fast as you can - Monday Run
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={leslie} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Leslie Olesch</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2022. 06. 21</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          The Hyper casual industry is ever-changing. One moment, you look at
          the current trends, and the next, everything has changed! To give our
          in-house studios a chance to take a break from daily routines and let
          them challenge their creativity without any restrictions, we held our
          first game jam, “Monday Run,” for two weeks, from June 8th to June
          21st.
        </p>
        <p>
          The rules were easy. Develop a prototype from scratch, ready for CPI
          testing. Everything from ideation to the production of creatives was
          up to our in- house teams. After a draw, groups were formed, and
          everyone was motivated and ready to work on their prototype.
          Naturally, the freedom to create any game you want can be inviting,
          but that was not all we wanted to give our studios. To reward everyone
          for their hard work, attractive cash prizes were waiting for the ones
          that could win the CPI challenge. The lower the CPI, the higher the
          reward.
        </p>
        <div data-content="1">
          <img content="height_1" src={poster} alt="poster" />
        </div>
        <p>
          With teams formed and rewards announced, everyone was ready to work.
          In entirely new environments, the teams quickly thought of many ideas,
          but deciding which project to start was not as easy. Time was
          pressing, and the studios had to decide soon. Various ideas, themes,
          and genres were born from just a few hours of brainstorming. Swords,
          Guns, Animals, Runners, Puzzles, and more made the final selection.
        </p>

        <div data-content="2">
          <div>
            <img content="height_2" src={firstGif} alt="IC" />
            <p className={styles.gameName}>Infinity Cannon</p>
          </div>
          <div>
            <img content="height_2" src={secondGif} alt="GHR" />
            <p className={styles.gameName}>Gun Head Run</p>
          </div>
          <p>• These two games are about to launch this month.</p>
        </div>

        <p>
          On the 22nd, creatives were submitted, marking the beginning of the
          marketability test phase—five days of waiting followed. Meanwhile,
          studio team members got to guess which creative would be the most
          successful. We ended up having two corrected guesses!
        </p>
        <p>
          The game jam experience was new for everyone, and returning to the
          roots of Hyper casual, fast ideation, and prototyping was a great
          refresher. Some of the games are in further development and preparing
          for launch!
        </p>
        <p>
          MondayOFF strives to offer fun activities to everyone in the team,
          in-house studios, and partner studios! Many more events are waiting.
          Don’t miss out and follow MondayOFF on{" "}
          <a
            className={styles.link}
            href="https://www.linkedin.com/company/30982214"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>{" "}
          and{" "}
          <a
            className={styles.link}
            href="https://discord.com/invite/Cf6smsvk7a"
            target="_blank"
            rel="noreferrer"
          >
            Discord!
          </a>
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default MondayRun;
