import React, { useCallback, useEffect, useState } from "react";
import styles from "./partnershippage.module.css";
import ScrollTop from "../../common/scrolltop/scrolltop";
import { Link } from "react-router-dom";
import header from "../header/header.module.css";
import partner1 from "../../image/partnerpage/par1.jpg";
import partner2 from "../../image/partnerpage/par2.jpg";
import partner3 from "../../image/partnerpage/par3.jpg";
import topImg from "../../image/partnerpage/par_fin.png";
import getGA from "../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../image/img_mondayoff_s.png";
const PartnershipPage = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  useEffect(() => {
    const menulist = document.getElementsByClassName(header.menulist);
    const logoImg = document.getElementsByClassName(header.logo);
    menulist[0].classList.remove(header.active);
    logoImg[0].classList.remove(header.active);
  }, []);
  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/partnership") {
      if (windowSize < 769) {
        nav[0].style.position = "sticky";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        gameText[0].style.color = "";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        publishText[0].style.color = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = 700;
        partnershipText[0].style.color = "#ffff";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
        articleText[0].style.color = "";
      } else {
        nav[0].style.position = "static";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "5px solid #ffff";
        partnershipText[0].style.fontWeight = 700;
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);

  const onClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <ScrollTop />
      <Helmet>
        <title>MondayOFF - Partnership</title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content="https://www.mondayoff.me/partnership"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/partnership"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
      </Helmet>
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>Partnership</h1>
          <a
            href="https://dashboard.mondayoff.me"
            target="_blank"
            rel="noreferrer"
            className={styles.button}
          >
            Submit Your Game
          </a>
        </header>
        <div className={styles.topmovebutton} onClick={onClick}>
          <div className={styles.toparrow}>⌃</div>
        </div>
        <section className={styles.topcontainer}>
          <div className={styles.imagecontainer}>
            <img src={topImg} alt="topImg" className={styles.topImg} />
          </div>
          <div className={styles.toptext}>
            <h1>Are you having</h1>
            <h1 className={styles.second}>trouble completing</h1>
            <h1 className={styles.third}>or</h1>
            <h1 className={styles.fourth}>We are here to </h1>
          </div>
          <div className={styles.bottomcontainer}>
            <h1>〉〉</h1>
            <a
              href="https://dashboard.mondayoff.me"
              target="_blank"
              rel="noreferrer"
              className={styles.secondbutton}
            >
              Submit Your Game
            </a>
          </div>
        </section>
        <section className={styles.middlecontainer}>
          <div className={styles.card1}>
            <div>
              <img className={styles.img} src={partner1} alt="first" />
            </div>
            <h1>How to create a unique concept?</h1>
            <p>
              Having a hard time when it comes to game-planning or ideation? 
              Don’t worry, that’s our expertise! 
            </p>
          </div>
          <div className={styles.card2}>
            <div>
              <img className={styles.img} src={partner2} alt="second" />
            </div>
            <h1>Competitive game design</h1>
            <p>
              Which design attracts the most users? With data-driven technology
              and expertise, we can create a great design to draw tons of users.
            </p>
          </div>
          <div className={styles.card3}>
            <div>
              <img className={styles.img} src={partner3} alt="third" />
            </div>
            <h1>We provide everything you need for your success!</h1>
            <p>
              Our team of experts will stay with you step by step and provide
              all the help and technical support you need for smooth game
              development.
            </p>
          </div>
        </section>
        <section className={styles.footercontainer}>
          <h1>Want to make people all over the world play your game?</h1>
          <a
            href="https://dashboard.mondayoff.me"
            target="_blank"
            rel="noreferrer"
            className={styles.footerbutton}
          >
            Submit Your Game
          </a>
        </section>
      </div>
    </>
  );
};

export default PartnershipPage;
