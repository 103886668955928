import React from "react";
import styles from "./aboutsection.module.css";
import backImg from "../../../../image/aboutsection.jpg";

const AboutSection = (props) => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h1>About MondayOFF</h1>
      </div>
      <div className={styles.contextfirst}>
        <p>
          MondayOFF is a mobile gaming company founded in 2018. With creativity
          and constantly evolving methods we always reach our goals. Our passion
          is to bring joy to the people with our games.
        </p>
      </div>
      <div className={styles.title}>
        <h1>Create Tomorrow Today</h1>
      </div>
      <div className={styles.contextsecond}>
        <p>
          Since our establishment in 2018, MondayOFF has grown more than three
          times on average every year, including the number of installations,
          monthly active users and sales. We will continue to evolve and push
          forward until we become global leaders in mobile casual game
          publishing. What will we accomplish next?
        </p>
      </div>
      <div>
        <img src={backImg} className={styles.image} alt="conference" />
      </div>
    </section>
  );
};

export default AboutSection;
