import React, { useEffect, useState, useCallback } from "react";
import styles from "./partnershipsubmit.module.css";
import ScrollTop from "../../../common/scrolltop/scrolltop";
import { init, send } from "emailjs-com";
import Swal from "sweetalert2";
import header from "../../header/header.module.css";
import getGA from "../../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../../image/img_mondayoff_s.png";
const PartnershipSubmit = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [skypeId, setSkypeId] = useState("");
  const [tellUs, setTellUs] = useState("");
  const [studioName, setStudioName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [gameUrl, setGameUrl] = useState("");
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  const firstNameInput = (e) => {
    setFirstName(e.target.value);
  };
  const lastNameInput = (e) => {
    setLastName(e.target.value);
  };
  const emailInput = (e) => {
    setEmail(e.target.value);
  };
  const tellUsInput = (e) => {
    setTellUs(e.target.value);
  };
  const studioNameInput = (e) => {
    setStudioName(e.target.value);
  };
  const skypeIdInput = (e) => {
    setSkypeId(e.target.value);
  };
  const portfolioLinkInput = (e) => {
    setPortfolioLink(e.target.value);
  };
  const companyNameInput = (e) => {
    setCompanyName(e.target.value);
  };
  const gameUrlInput = (e) => {
    setGameUrl(e.target.value);
  };

  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/partnershipsubmit") {
      if (windowSize < 769) {
        nav[0].style.position = "static";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        publishText[0].style.color = "";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        gameText[0].style.color = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = 700;
        partnershipText[0].style.color = "#ffff";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
        articleText[0].style.color = "";
      } else {
        nav[0].style.position = "static";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "5px solid #ffff";
        partnershipText[0].style.fontWeight = 700;
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);

  useEffect(() => {
    init(process.env.REACT_APP_USER_INIT);
  }, []);

  let regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

  const onSubmit = () => {
    if (email === "" || firstName === "" || lastName === "")
      Swal.fire({ icon: "error", title: "Please enter a must-have item." });
    else if (regExp.test(email) === false)
      Swal.fire({ icon: "error", title: "Please check your e-mail." });
    else {
      send(
        process.env.REACT_APP_USER_ID,
        process.env.REACT_APP_TEMPLETE_ID_PARTNERSHIP,
        {
          submit_Name: "Partnership",
          from_Email: email,
          skype_Id: skypeId === "" ? "undefined" : skypeId,
          first_Name: firstName,
          last_Name: lastName,
          tell_Us: tellUs,
          studio_Name: studioName,
          company_Name: companyName,
          portfolio_Link: portfolioLink,
          game_Url: gameUrl === "" ? "undefined" : gameUrl,
        }
      )
        .then(
          Swal.fire({
            icon: "success",
            title: `It's been sent.`,
            text: `I'll get back to you.`,
            showConfirmButton: false,
            timer: 1400,
            timerProgressBar: true,
          })
        )
        .then(
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        );
    }
  };

  useEffect(() => {
    const firstNameInput = document.getElementsByClassName(
      styles.firstnameinput
    );
    const firstNameAlert = document.getElementsByClassName(
      styles.firstalertmessage
    );
    firstNameInput[0].onfocus = () => {
      firstNameAlert[0].innerHTML = "";
    };
    firstNameInput[0].onblur = () => {
      if (firstName.length === 0) {
        firstNameAlert[0].innerHTML = "Please Check your first name";
      } else {
        firstNameAlert[0].innerHTML = "";
      }
    };
  });

  useEffect(() => {
    const lastNameInput = document.getElementsByClassName(styles.lastnameinput);
    const lastNameAlert = document.getElementsByClassName(
      styles.lastalertmessage
    );
    lastNameInput[0].onfocus = () => {
      lastNameAlert[0].innerHTML = "";
    };
    lastNameInput[0].onblur = () => {
      if (lastName.length === 0) {
        lastNameAlert[0].innerHTML = "Please Check your last name";
      } else {
        lastNameAlert[0].innerHTML = "";
      }
    };
  });

  useEffect(() => {
    const emailInput = document.getElementsByClassName(styles.emailinput);
    const emailAlert = document.getElementsByClassName(
      styles.eamilalertmessage
    );
    emailInput[0].onfocus = () => {
      emailAlert[0].innerHTML = "";
    };
    emailInput[0].onblur = () => {
      if (regExp.test(email) === false) {
        emailAlert[0].innerHTML = "Please Check your email";
      } else {
        emailAlert[0].innerHTML = "";
      }
    };
  });

  return (
    <>
      <ScrollTop />
      <Helmet>
        <title>MondayOFF - Partnership</title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content="https://www.mondayoff.me/partnershipsubmit"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/partnershipsubmit"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF - Partnership"
          data-react-helmet="true"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.formcontainer}>
          <div></div>
          <div className={styles.title}>
            <h1>Submit your game</h1>
            <h2>
              <div></div> Get Free 24/7 Support for you. <div></div>
            </h2>
          </div>
          <div className={styles.subtitle}>
            <h1>Your details</h1>
          </div>
          <form className={styles.inputcontainer}>
            <div className={styles.namecontainer}>
              <div className={styles.firstname}>
                <h1>First name</h1>
                <input
                  type="text"
                  onChange={firstNameInput}
                  className={styles.firstnameinput}
                ></input>
                <div className={styles.firstalertmessage}></div>
              </div>
              <div className={styles.lastname}>
                <h1>last name</h1>
                <input
                  type="text"
                  onChange={lastNameInput}
                  className={styles.lastnameinput}
                ></input>

                <div className={styles.lastalertmessage}></div>
              </div>
            </div>
            <div className={styles.emailandskype}>
              <div className={styles.email}>
                <h1>email</h1>
                <input
                  type="email"
                  onChange={emailInput}
                  className={styles.emailinput}
                ></input>
                <div className={styles.eamilalertmessage}></div>
              </div>
              <div className={styles.skypeid}>
                <h1>skype (Optional)</h1>
                <input type="text" onChange={skypeIdInput}></input>
              </div>
            </div>
            <div className={styles.tellus}>
              <h1>Tell us What Kind of help do you need.</h1>
              <textarea onChange={tellUsInput}></textarea>
            </div>

            <div className={styles.teamcontainer}>
              <h1>Studio detail</h1>
            </div>
            <div className={styles.studionameandcompany}>
              <div className={styles.studioname}>
                <h1>Studio name</h1>
                <input type="text" onChange={studioNameInput}></input>
              </div>
              <div className={styles.companyname}>
                <h1>Company website</h1>
                <input type="text" onChange={companyNameInput}></input>
              </div>
            </div>
            <div className={styles.portfolioandurl}>
              <div className={styles.portfoliolink}>
                <h1>Portfolio</h1>
                <input type="text" onChange={portfolioLinkInput}></input>
              </div>
              <div className={styles.gameurl}>
                <h1>Game URL (Optional)</h1>
                <input type="text" onChange={gameUrlInput}></input>
              </div>
            </div>
          </form>
          <button className={styles.submitbutton} onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default PartnershipSubmit;
