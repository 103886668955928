import React from "react";
import styles from "../../articles.module.scss";
import dunkunMainImg from "../../../../image/blog/writing/dunkun's Interview/mainimg.png";
import aleIcon from "../../../../image/articles/Ale.png";
const DunkunInterview = (props) => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={dunkunMainImg} alt="" />
      <h1 className={styles.title}>
        MondayOFF On-Record: Meet Dunkun - MondayOff Co-Founder
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021.11.12</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          We want you to know us better. We are starting this new series of
          interviews to learn more about the history of MondayOFF and the people
          behind it. For our first interview, we decided to talk with Dunkun,
          the founder of MondayOFF. I hope you enjoy this brief but informative
          interview and know more about us. 
        </p>
        <h2>Could you tell us a bit about MondayOFF? How did it all start?</h2>
        <p>
          Our co-founders and I wanted to develop our games and reach global
          audiences. At that time, most Korean mobile games were focusing on
          East Asia. So we believed that we could be one of the greatest gaming
          companies in the global market. In 2018, we founded our company,
          starting with a small office. That was the beginning of our story.
        </p>

        <h2>
          What was your motivation for creating a game studio and now publisher?
        </h2>
        <p>
          Whenever we see a lot of users from around the world who are playing
          our games, we say, 'we have been doing a great job so far,' and 'Yeah,
          look. We could be the #1 game publisher in East-Asia and #10
          globally.’ We are so proud of what we have achieved so far, and we are
          also really looking forward to seeing what will happen in the future.
        </p>

        <h2>
          What are the biggest challenges in managing a Hyper-casual studio?
        </h2>
        <p>
          Hyper-casual is not just a game. It must include psychological factors
          on its core mechanism to be successful. Those psychological factors
          should represent the common interest of huge audiences. It sounds like
          nothing special, but we face many failures during the development
          phase. However, this does not discourage us from moving on to the next
          project.
        </p>

        <h2>
          Before creating MondayOFF, did you have an interest in mobile games?
        </h2>
        <p>
          Yes. I love to play mobile games, and my old smartphone didn't have
          enough storage to download other games because I already had 100+
          games on it. That can explain how much I love mobile games. But after
          being the founder of MondayOFF, I couldn't play mobile games a lot. I
          should have played and enjoyed games, but now I usually judge and
          analyze game mechanisms.
        </p>

        <h2>What do you wish for MondayOFF in the future?</h2>
        <p>
          I wish MondayOFF to be one of the Top #10 Hyper-casual publishers
          globally. That is what I want to achieve within two years from now. We
          have been doubling all of our data during the past couple of years. In
          2021, MondayOFF achieved 50 million downloads (25 million downloads
          during 2018 ~ 2020) with 10 million MAU (5 million MAU during 2020).
          The DAU and revenue are much more than our estimation. I hope you can
          wait for us and see our success in the future.
        </p>
        <p>
          I hope you enjoyed reading this brief interview and learned more about
          MondayOFF. Please visit our LinkedIn and follow us there to know more
          about us.
        </p>

        <a
          className={styles.link}
          href="https://www.linkedin.com/in/dunkun-kang-720233163/"
          target="_blank"
          rel="noreferrer"
        >
          Dunkun’s Linkedin
        </a>
        <a
          className={styles.link}
          href="https://www.linkedin.com/company/mondayoff-inc"
          target="_blank"
          rel="noreferrer"
        >
          MondayOFF Linkedin
        </a>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default DunkunInterview;
