import React from "react";
import styles from "../../articles.module.scss";
import firstThumbnail from "../../../../image/articles/Article01.png";
import one from "../../../../image/articles/HybridCasual/one.png";
import two from "../../../../image/articles/HybridCasual/two.png";
import three from "../../../../image/articles/HybridCasual/three.png";
import four from "../../../../image/articles/HybridCasual/four.png";
import five from "../../../../image/articles/HybridCasual/five.png";
import six from "../../../../image/articles/HybridCasual/six.jpg";
import seven from "../../../../image/articles/HybridCasual/seven.jpg";
import eight from "../../../../image/articles/HybridCasual/eight.jpg";
import ten from "../../../../image/articles/HybridCasual/ten.jpg";
import eleven from "../../../../image/articles/HybridCasual/eleven.jpg";
import twelve from "../../../../image/articles/HybridCasual/twelve.jpg";
import thirteen from "../../../../image/articles/HybridCasual/thirteen.jpg";
import fourteen from "../../../../image/articles/HybridCasual/fourteen.gif";
import fifteen from "../../../../image/articles/HybridCasual/fifteen.gif";
import aleIcon from "../../../../image/articles/Ale.png";

const HyperCasual = () => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={firstThumbnail} alt="thumnail" />
      <h1 className={styles.title}>What is Hybrid-Casual to MondayOFF?</h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2023. 5. 31</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          Hybrid-Casual has been creating quite a buzz recently. At MondayOFF,
          we believe that hybrid-casual is a natural progression from
          hyper-casual games.
        </p>
        <p>So, what exactly is hybrid-casual?</p>
        <h2>
          Hybrid-Casual is a model used for effectively increasing a game’s LTV.
        </h2>
        <p>
          If you’re involved in the hyper-casual industry. In that case, you
          probably know that the Lifetime Value (LTV) of hyper-casual games is
          lower than other mobile game genres. This is because hyper-casual
          games have a lower playtime and D7-D30 RET, targeting players who want
          to play easier games for a shorter time and enjoy the distraction of
          playing anywhere and anytime.
        </p>
        <p>
          An example of improving LTV is seen in the game{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=life%20of%20mellow&c=apps&hl=en-KR"
            target="_blank"
            rel="noreferrer"
          >
            Life of Mellow.
          </a>{" "}
          Many meta-layers in this game make the players more attached to the
          Mellow villagers (game characters). The player needs to take care of
          their town, feeling the responsibility to keep checking on their
          village, thus lowering the chances of game abandonment and eventually
          increasing the RET. With the choices of RVs and IAP, the LTV is much
          higher than usual hyper-casual games.
        </p>
        <div data-content="1">
          <img src={one} content="square_1" alt="img" />
        </div>
        <p>
          The graph depicts that Life of Mellow continuously had a higher LTV
          than{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=gun%20head%20run&c=apps&hl=en-KR"
            target="_blank"
            rel="noreferrer"
          >
            Gun Head Run
          </a>
          , especially in the long term, without a drop in LTV after 150 days.
        </p>
        <p>
          The surge in hybrid-casual games indicates that players want more
          engaging gaming experiences, explaining the recent increase in
          published hybrid-casual games. According to{" "}
          <a
            className={styles.link}
            href="https://sensortower.com/hybridcasual-games-playbook-2023"
            target="_blank"
            rel="noreferrer"
          >
            Sensor Tower
          </a>
          , hybrid-casual games were downloaded over 5 billion times in 2022,
          which means that the model has been growing steadily throughout the
          years, with a 3% increase from 2021 to 2022.
        </p>
        <h2>The Importance of LTV in hybrid-casual games.</h2>
        <p>
          You might be wondering why LTV is so critical in-game metrics.
          Regarding hyper and hybrid-casual games, LTV projects the total
          potential revenue a player can generate in their user lifespan.
        </p>
        <p>Several factors determine LTV; these factors are:</p>
        <ul>
          <li>The average revenue per user (ARPU)</li>
          <li>Retention Rate (RET)</li>
          <li>In-App Purchases (IAP)</li>
          <li>Ad revenue (including Rewarded, Interstitial, and Banner ads)</li>
        </ul>
        <p>
          Analyzing all of these factors is essential since it can allow you,
          the developer, to know insights into each player's values. LTV is
          crucial as it helps developers and publishers understand the
          monetization potential of the game, the user base, and if the added
          meta-layers or LiveOPs added to the game are increasing the revenue.
          Ultimately, a high LTV indicates that players are more engaged in the
          game, drawn to the IAP, and can contribute to the game's success in
          the long run.
        </p>
        <h2>
          Adding Core Meta-layers to enhance player experience for higher RET
          and LTV.
        </h2>
        <p>
          It is proven that games with additional or more complex meta layers
          implemented to enhance the player’s experience contribute to a higher
          RET and LTV. For more clarity, meta-layers are elements that create a
          more advanced system for level progression and goals for the player
          apart from the core gameplay. These could be in the forms of upgrade
          boosts, rewarded collection systems, in-game design customizations,
          premium currencies, and building mechanics. Incorporating these core
          meta layers makes the player want to return and keep playing the game
          continuously and attain a higher sense of achievement.
        </p>
        <p>
          In our games,{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=like%20a%20pizza&c=apps"
            target="_blank"
            rel="noreferrer"
          >
            Like a Pizza
          </a>{" "}
          and{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/apps/details?id=com.mondayoff.drop"
            target="_blank"
            rel="noreferrer"
          >
            Change and Drop
          </a>
          , the difference in retention is quite visible as seen in the graph
          below. Like a Pizza is an idle arcade game focusing more on map
          expansion and RV boosts. Change and Drop is a classic collection
          hyper-casual game that was very popular in 2021.
        </p>
        <div data-content="2">
          <img src={two} content="square_2" alt="img" />
          <img src={three} content="square_2" alt="img" />
        </div>
        <p>
          The chart above shows that Like a Pizza has a higher RET rate; this is
          due to the game having more added meta layers and making the player
          feel they have more opportunities to progress and fully manage the
          game. In contrast, Change and Drop, as a hyper-casual game, relies on
          the design of having short levels and simple gameplay, which the
          downside is that it tends to feel repetitive for the players causing
          low retention. The same is seen in the playtime. Like a Pizza's
          playtime is much higher than that of Change and Drop.
        </p>
        <p>
          Adding Meta-Layers is essential to increase engagement time and the
          likeliness of players having longer sessions. So many hyper-casual
          games are evolving by adding different levels that can be unlocked
          through specific actions and introducing expansion items or in-game
          boosts for users to feel like they are better than the average player.
        </p>
        <h2>Hybrid-casual is a monetization model, not a separate genre.</h2>
        <p>
          Hybrid-casual has derived from current hyper-casual games and casual
          games such as puzzles. We have seen this with the evolution of idle
          arcade, clickers, and simulation games. One example is seen with
          Rewarded Video Ads (RVs) which encourage players to obtain more money,
          have more resources, boost, or automate their play in these games.
          <a
            className={styles.link}
            href="https://sensortower.com/hybridcasual-games-playbook-2023"
            target="_blank"
            rel="noreferrer"
          >
            Around 50% of revenue in hybrid-casual games comes from In-App
            Purchases (IAP) and the other from In-App Ads (IAA).
          </a>
        </p>
        <p>
          One example in which we can compare a hyper-casual game and a
          hybrid-casual game revenue ratio is in our games{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=gun%20head%20run&c=apps"
            target="_blank"
            rel="noreferrer"
          >
            Gun Head Run
          </a>{" "}
          and{" "}
          <a
            className={styles.link}
            href="https://play.google.com/store/search?q=life%20of%20mellow&c=apps"
            target="_blank"
            rel="noreferrer"
          >
            Life Of Mellow.
          </a>
        </p>
        <div data-content="2">
          <img src={four} content="square_2" alt="img" />
          <img src={five} content="square_2" alt="img" />
        </div>
        <p>
          The above chart shows that Gun Head Run's revenue source is mostly
          IAA, making up 98% of its total revenue. A contrasting example is Life
          of Mellow. The revenue source ratio in Life of Mellow is almost
          exactly split in half between IAP and IAA. The chart shows that IAP
          makes up about 52% of the total revenue, which is only slightly higher
          than the IAA revenue making up 48%.
        </p>
        <p>
          Hybrid-casual games like Life of Mellow have a more elaborate game
          economy. They typically include collection systems, upgrades,
          customization, challenges, and other additional incentives (such as
          RVs) that extend the economy for players to stick around and pay for
          those items in the game. For this reason, it is essential to
          understand the player's needs and optimize the Average Revenue Per
          User (ARPU) accordingly.
        </p>
        <p>
          For example, Life of Mellow offers several paid Downloadable Content
          (DLCs). The DLCs allow the player to have substantial buildings to
          improve their mellow's life, increase their happiness, and lower their
          chances of leaving the village. One goal of the game is to maintain
          the Mellow's happiness high. If they are unhappy, they will leave the
          town. Players become drawn into these purchases since they attempt to
          make their 'Mellows' stay and increase their happiness. They can
          achieve this by paying for packages that allow them to have unique
          buildings that can increase the Mellow's life quality exponentially.
          Each Mellow also has a name, age, and family. These factors make the
          player more attached to the game and its characters, attracting them
          more to these DLCs.
        </p>
        <div data-content="3">
          <img src={six} content="height_3" alt="img" />
          <img src={seven} content="height_3" alt="img" />
          <img src={eight} content="height_3" alt="img" />
        </div>
        <p>
          Another example from the game is a 'Premium Pass,' introduced as a
          LiveOps for better engagement, allowing players to obtain additional
          resources as a result for completing in-game goals. As seen in the
          images, there are 'Hire tickets' and 'Build Tickets' to make the
          game-play easier.
        </p>
        <p>
          While in hybrid-casual, there is a big focus on IAPs, RVs also play a
          significant role. All of these monetization factors, if correctly
          implemented in the game, after testing and profoundly analyzing the
          game data, an LTV increase is highly likely to occur.
        </p>
        <h2>
          The importance of IAAs in hybrid-casual games: Less IS and more RVs
        </h2>
        <p>
          In many hybrid-casual games, IS ads are shown less or not at all to
          increase the views of rewarded ads and IAP in the long run. It also
          prevents players from getting tired after watching IS ads, which
          decreases the retention rate due to IS ad fatigue. Recognizing the
          appropriate timing, placement, and reward amount for RVs is essential
          to increase players’ desire to watch them. In the case of Life of
          Mellow, it does not contain IS. Players can watch RVs to increase
          their productivity.
        </p>
        <div data-content="4">
          <img src={ten} content="height_4" alt="img" />
          <img src={eleven} content="height_4" alt="img" />
          <img src={twelve} content="height_4" alt="img" />
          <img src={thirteen} content="height_4" alt="img" />
        </div>
        <p>
          Players who don’t feel like spending their money in IAP can still
          progress for “free.” Some RVs are time-limited, making the player feel
          urgency to watch them before the offer disappears.
        </p>
        <p>
          The combination of both IAA and IAP creates a hybrid monetization
          model. For a monetization model to work, the game developer must
          understand how to target free-to-play and paying players. For this
          reason, thorough data analysis on a game is critical.
        </p>
        <p>
          Due to the competitive nature of the mobile gaming market and its
          constant changes, players are seeking more profound and engaging
          gaming experiences. Hyper-casual games need to increase their LTV, so
          hybrid-casual is the extension that hyper-casual needs.
        </p>
        <h2>The key factors for low CPI in Hybrid-Casual games</h2>
        <p>
          Hyper-casual uses marketing techniques and creatives to entice players
          to download and play the games immediately. Hybrid-casual is an
          extension of hyper-casual, so publishers use the same methods to
          maintain that low CPI that hyper-casual is known for.
        </p>
        <p>Some of the common factors include:</p>
        <ul>
          <li>
            Showing advertisements that challenge the player to think they can
            do better than what is shown in the advertisement
          </li>
          <li>Ads showing the game play at a fast pace</li>
          <li>Showing just the core game mechanics of the game</li>
          <li>In game expansions and development</li>
          <li>Fail settings</li>
          <li>Playable ads</li>
        </ul>
        <div data-content="2">
          <img src={fourteen} content="height_2" alt="img" />
          <img src={fifteen} content="height_2" alt="img" />
        </div>
        <p>
          Hyper-casual ads tend to show simple gameplay; hybrid-casual ads do
          something similar while showing diverse gameplay, modes, boosts, and
          skins.
        </p>
        <p>
          Both creatives show the game's core gameplay and the goals the players
          can reach. The key is always to display the main characteristics of
          the game and convey emotion to the player for them to want to download
          the game and achieve the goals presented in the creative ad.
        </p>
        <h2>To conclude,</h2>
        <p>
          The hybrid-casual model brings many benefits to the ever-changing
          gaming industry. Many hyper-casual games are evolving by adding more
          engaging levels and game modes, such as battle mode and multiplayer.
          New hybrid monetization layers have also been introduced, such as
          battle/premium passes, premium currencies, subscriptions, DLCs, and
          others. These factors can contribute to higher RV impressions and
          motivate players to return to the game, effectively increasing LTV and
          Retention numbers. MondayOFF has your back by providing the extensive
          knowledge and tools you need to make the next hybrid-casual hit!
        </p>
        <p>
          Make sure to be part of our events and have the opportunity to publish
          with us!
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default HyperCasual;
