import React, { useState, useCallback } from "react";
import VideoSection from "./videosection/videosection";
import SubmitSection from "./submitsection/submitsection";
import GameSection from "./gamesection/gamesection";
import AboutSection from "./aboutsection/aboutsection";
import styles from "./home.module.css";
import header from "../../header/header.module.css";
import FooterSection from "./footersection/footersection";
import ScrollTop from "../../../common/scrolltop/scrolltop";
import { useEffect } from "react";
import getGA from "../../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../../image/img_mondayoff_s.png";
const Home = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  const onClick = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    const menulist = document.getElementsByClassName(header.menulist);
    menulist[0].classList.remove(header.active);
  }, []);

  useEffect(() => {
    const menulist = document.getElementsByClassName(header.menulist);
    const logoImg = document.getElementsByClassName(header.logo);
    menulist[0].classList.remove(header.active);
    logoImg[0].classList.remove(header.active);
  }, []);

  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/") {
      if (windowSize < 769) {
        gameText[0].style.color = "";
        gameText[0].style.fontWeight = "";
        publishText[0].style.color = "";
        publishText[0].style.fontWeight = "";
        partnershipText[0].style.color = "";
        partnershipText[0].style.fontWeight = "";
        articleText[0].style.color = "";
        articleText[0].style.fontWeight = "";
      } else {
        nav[0].style.position = "sticky";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        window.history.scrollRestoration = "manual";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);

  return (
    <>
      <ScrollTop />
      <Helmet>
        <title>MondayOFF</title>
        <link rel="icon" href={favicon} />
        <meta name="apple-mobile-web-app-title" content="MondayOFF" />
        <meta
          name="keywords"
          content="먼데이오프,MondayOFF,Mondayoff,Monday off, mondayoff, monday off"
        />
        <meta
          name="description"
          content="MondayOFF is a mobile games developer and publisher specialized in Hypercasual games. With creativity and constantly evolving methods, we aim to launch your game to the top of the charts and build a worldwide audience to enjoy your games."
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF is a mobile games developer and publisher specialized in Hypercasual games. With creativity and constantly evolving methods, we aim to launch your game to the top of the charts and build a worldwide audience to enjoy your games."
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />

        <meta
          property="og:url"
          content="https://www.mondayoff.me/"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF is a mobile games developer and publisher specialized in Hypercasual games. With creativity and constantly evolving methods, we aim to launch your game to the top of the charts and build a worldwide audience to enjoy your games."
          data-react-helmet="true"
        />
      </Helmet>
      <article className={styles.container}>
        <div className={styles.sectionlist}>
          <div className={styles.button} onClick={onClick}>
            <div className={styles.toparrow}>⌃</div>
          </div>
          <VideoSection />
          <SubmitSection />
          <AboutSection />
          <GameSection />
          <FooterSection />
        </div>
      </article>
    </>
  );
};

export default Home;
