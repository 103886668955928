import React from "react";
import styles from "./footersection.module.css";
import { Link } from "react-router-dom";
import handShake from "../../../../image/img_handshake.png";
import fistBumpLeft from "../../../../image/img_pow_left.png";
import fistBumpRight from "../../../../image/img_pow_right.png";
import powImg from "../../../../image/img_pow.png";
const FooterSection = (props) => {
  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <p>Together we can climb the charts.</p>
      </div>
      <div className={styles.buttoncontainer}>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
          className={styles.publishbutton}
        >
          <p>Publishing</p>
          <img
            src={handShake}
            alt="handshake"
            className={styles.handShake}
          ></img>
        </a>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
          className={styles.partnershipbutton}
        >
          <p>Partnership</p>
          <img
            src={fistBumpLeft}
            alt="fistBumpLeft"
            className={styles.fistBumpLeft}
          />
          <img
            src={fistBumpRight}
            alt="fistBumpRight"
            className={styles.fistBumpRight}
          />
          <img src={powImg} alt="powimg" className={styles.powImg} />
        </a>
      </div>
    </section>
  );
};

export default FooterSection;
