import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import ScrollTop from "../../../common/scrolltop/scrolltop";
import styles from "./game.module.css";
import header from "../../header/header.module.css";
//import { gameList } from "../../../common/gamelist/gamelist.js";
import appstore from "../../../image/appstoreicon/btn_putter_appstore.png";
import googlestore from "../../../image/appstoreicon/btn_putter_google.png";
import getGA from "../../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../../image/img_mondayoff_s.png";
const Game = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [games, setGames] = useState([]);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    axios(`https://server.mondayoff.me/games/icons`, { method: "GET" }).then(
      (res) => {
        setGames(
          res.data.sort(
            (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)
          )
        );
      }
    );
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  useEffect(() => {
    const menulist = document.getElementsByClassName(header.menulist);
    const logoImg = document.getElementsByClassName(header.logo);
    menulist[0].classList.remove(header.active);
    logoImg[0].classList.remove(header.active);
  }, []);
  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/games") {
      if (windowSize < 769) {
        nav[0].style.position = "sticky";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = 700;
        gameText[0].style.color = "#ffff";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        publishText[0].style.color = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        partnershipText[0].style.color = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
        articleText[0].style.color = "";
      } else {
        nav[0].style.position = "sticky";
        gameMenu[0].style.borderBottom = "5px solid #ffff";
        gameText[0].style.fontWeight = 700;
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);
  const onClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <ScrollTop />
      <Helmet>
        <title>MondayOFF - Games</title>
        <link rel="icon" href={favicon} data-react-helmet="true" />
        <meta
          name="description"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content="https://www.mondayoff.me/games"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/games"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF - Games"
          data-react-helmet="true"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.button} onClick={onClick}>
          <div className={styles.toparrow}>⌃</div>
        </div>
        <header className={styles.header}>Games</header>
        <section className={styles.gamecontainer}>
          {games.map((game) => {
            return (
              <div key={game.id}>
                <div className={styles.game}>
                  <div className={styles.top}>{game.gameName}</div>
                  <div className={styles.bottom}>
                    <a href={game.iosUrl} target="_blank" rel="noreferrer">
                      <img src={appstore} alt="" className={styles.appstore} />
                    </a>
                    <a href={game.aosUrl} target="_blank" rel="noreferrer">
                      <img
                        src={googlestore}
                        alt=""
                        className={styles.googlestore}
                      />
                    </a>
                  </div>
                  <img
                    src={game.iconUrl}
                    alt={game.gameName}
                    className={styles.img}
                  />
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default Game;
