import React from "react";
import styles from "../../articles.module.scss";
import thumbnail from "../../../../image/articles/200m/thumbnail.png";
import kellyIcon from "../../../../image/articles/kelly.png";
import first from "../../../../image/articles/200m/top3.png";
import second from "../../../../image/articles/200m/pizza.png";
import third from "../../../../image/articles/200m/gun.png";
import fourth from "../../../../image/articles/200m/wacky.png";
export default function TwoMillon() {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thumbnail} alt="" />
      <h1 className={styles.title}>
        We're back with exciting news! MondayOFF has reached 200 million
        downloads!
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={kellyIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Kelly</span>
            <span>•</span>
            <span>Human Resources</span>
          </span>
          <span content="date">2023.07.12</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          MondayOFF was founded in 2018 as the first hyper-casual game developer
          in South Korea. As of summer 2023, the total number of downloads in 60
          countries worldwide has exceeded 200 million! 🎉 In light of this
          achievement, we would like to take this opportunity to introduce you
          to the top three most downloaded games from MondayOFF.
        </p>
        <div data-content="1">
          <img content="width_1" src={first} alt="" />
        </div>

        <p>
          The first game with the highest number of downloads is Like a Pizza.
          🍕 It was launched in May 2022 and already accounts for more than 10%
          of the total downloads in just over a year.
        </p>
        <div data-content="1">
          <img content="height_1" src={second} alt="" />
        </div>
        <h2>Like a Pizza</h2>
        <p>
          Like a Pizza is an idle arcade game that emphasizes growth, resource
          accumulation, and expansion through simple joystick controls. The idle
          arcade genre is characterized by the absence of "failure" in stages,
          resulting in extended playtime and high retention rates. Players enjoy
          running their business by selling pizza, expanding their store with
          profits, and hiring part-time employees.
        </p>
        <div data-content="1">
          <img content="height_1" src={third} alt="" />
        </div>
        <h2>Gun Head Run</h2>
        <p>
          The second most downloaded game, Gun Head Run, is a runner game that
          was released in June of last year. This hyper-casual game features a
          stickman with a gun in place of its head, firing bullets as it runs
          through gates and obstacles. The player acquires various types of guns
          and shells as they run and try to get through the most obstacles to
          earn the most amount of money. The success of the game comes from its
          intuitive gameplay, which uses simple side-to-side controls and
          easy-to-understand graphics.
        </p>
        <div data-content="1">
          <img content="height_1" src={fourth} alt="" />
        </div>
        <h2>Wacky Jelly</h2>
        <p>
          Wacky Jelly, our third most downloaded game, was released in June
          2021. The game starts with an object spinning at the top, and the
          player needs to aim the object at an exact location to win. In true
          hyper-casual fashion, each stage offers a different challenge through
          the same game mechanic. This is what makes it an addictive game that
          has remained relevant for a long time.
        </p>
        <p>
          We would like to express our gratitude to all the users who have
          downloaded and played our games, and to our studio team and partner
          studios for the amazing games we have published so far! If you are a
          studio looking to publish your next hit, head to our dashboard now.
        </p>
        <p>Translation : Dahlia</p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
}
