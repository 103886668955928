import React, { useEffect, useState, useCallback } from "react";
import styles from "./publishingsubmit.module.css";
import ScrollTop from "../../../common/scrolltop/scrolltop";
import classNames from "classnames";
import { init, send } from "emailjs-com";
import Swal from "sweetalert2";
import header from "../../header/header.module.css";
import getGA from "../../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../../image/img_mondayoff_s.png";
const PublishingSubmit = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [gameTitle, setGameTitle] = useState("");
  const [gameLink, setGameLink] = useState("");
  const [aboutGame, setAboutGame] = useState("");
  const [fullName, setFullName] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [email, setEmail] = useState("");
  const [studioName, setStudioName] = useState("");
  const [attachLink, setAttachLink] = useState("");
  const [clickButton, setClickButton] = useState();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  const titleInput = (e) => {
    setGameTitle(e.target.value);
  };
  const linkInput = (e) => {
    setGameLink(e.target.value);
  };
  const emailInput = (e) => {
    setEmail(e.target.value);
  };
  const aboutInput = (e) => {
    setAboutGame(e.target.value);
  };
  const studioNameInput = (e) => {
    setStudioName(e.target.value);
  };
  const fullNameInput = (e) => {
    setFullName(e.target.value);
  };
  const portfolioLinkInput = (e) => {
    setPortfolioLink(e.target.value);
  };
  const teamSizeInput = (e) => {
    setTeamSize(e.target.value);
  };
  const attachLinkInput = (e) => {
    setAttachLink(e.target.value);
  };

  useEffect(() => {
    const yesButton = document.getElementsByClassName(styles.yesbutton);
    const noButton = document.getElementsByClassName(styles.nobutton);
    const formContainer = document.getElementsByClassName(styles.formcontainer);
    if (clickButton === true) {
      const hideAttach = document.getElementsByClassName(styles.attachlink);
      formContainer[0].classList.remove(classNames(styles.bottomextension));
      noButton[0].style.background = "";
      noButton[0].style.color = "";
      noButton[0].style.border = "";
      yesButton[0].style.background = "#f7931e";
      yesButton[0].style.color = "white";
      yesButton[0].style.border = "#f7931e 1px";
      hideAttach[0].style.display = "none";
    } else if (clickButton === false) {
      const showAttach = document.getElementsByClassName(styles.attachlink);
      formContainer[0].classList.add(classNames(styles.bottomextension));
      noButton[0].style.background = "#f7931e";
      noButton[0].style.color = "white";
      noButton[0].style.border = "#f7931e 1px";
      yesButton[0].style.background = "";
      yesButton[0].style.color = "";
      yesButton[0].style.border = "";
      showAttach[0].style.display = "flex";
    }
  });
  useEffect(() => {
    const gameInput = document.getElementsByClassName(styles.gametitleinput);
    const gameTitleAlert = document.getElementsByClassName(
      styles.gametitlealertmessage
    );
    gameInput[0].onfocus = (e) => {
      gameTitleAlert[0].innerHTML = "";
    };
    gameInput[0].onblur = (e) => {
      if (gameTitle.length === 0) {
        gameTitleAlert[0].innerHTML = "Please enter a valid game title";
      } else {
        gameTitleAlert[0].innerHTML = "";
      }
    };
  }, [gameTitle]);
  let regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  useEffect(() => {
    const emailInput = document.getElementsByClassName(styles.emailinput);
    const emailAlert = document.getElementsByClassName(styles.mailalertmessage);
    emailInput[0].onfocus = () => {
      emailAlert[0].innerHTML = "";
    };
    emailInput[0].onblur = () => {
      if (regExp.test(email) === false) {
        emailAlert[0].innerHTML = "Please Check your email";
      } else {
        emailAlert[0].innerHTML = "";
      }
    };
  });
  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/publishingsubmit") {
      if (windowSize < 769) {
        nav[0].style.position = "static";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = 700;
        publishText[0].style.color = "#ffff";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        gameText[0].style.color = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        partnershipText[0].style.color = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
        articleText[0].style.color = "";
      } else {
        nav[0].style.position = "static";
        publishPage[0].style.borderBottom = "5px solid #ffff";
        publishText[0].style.fontWeight = 700;
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);

  useEffect(() => {
    init(process.env.REACT_APP_USER_INIT);
  }, []);

  const onSubmit = () => {
    if (email === "" && gameTitle === "")
      Swal.fire({ icon: "error", title: "Please enter a must-have item." });
    else if (regExp.test(email) === false)
      Swal.fire({ icon: "error", title: "Please check your e-mail." });
    else {
      send(
        process.env.REACT_APP_USER_ID,
        process.env.REACT_APP_TEMPLETE_ID_PUBLISH,
        {
          submit_Name: "Publishing",
          from_Email: email,
          game_Title: gameTitle,
          game_Link: gameLink,
          about_Game: aboutGame,
          studio_Name: studioName,
          full_Name: fullName,
          portfolio_Link: portfolioLink,
          team_Size: teamSize,
          attach_Link: clickButton === true ? "Experienced." : attachLink,
        }
      )
        .then(
          Swal.fire({
            icon: "success",
            title: `It's been sent.`,
            text: `I'll get back to you.`,
            showConfirmButton: false,
            timer: 1400,
            timerProgressBar: true,
          })
        )
        .then(
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        );
    }
  };
  return (
    <>
      <ScrollTop />
      <Helmet>
        <title>MondayOFF - Publish</title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
        />
        <meta
          property="og:url"
          content="https://www.mondayoff.me/publishingsubmit"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/publishingsubmit"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.formcontainer}>
          <div></div>
          <div className={styles.title}>
            <h1>Submit your game</h1>
          </div>
          <div className={styles.subtitle}>
            <h1>Your game</h1>
          </div>
          <form className={styles.inputcontainer}>
            <div className={styles.gametitlecontainer}>
              <div className={styles.gametitle}>
                <h1>Game title</h1>

                <input
                  type="text"
                  onChange={titleInput}
                  className={styles.gametitleinput}
                ></input>
                <div className={styles.gametitlealertmessage}></div>
              </div>
              <div className={styles.gamelink}>
                <h1>Gameplay video link (Additional)</h1>
                <input type="text" onChange={linkInput}></input>
              </div>
            </div>
            <div className={styles.aboutgame}>
              <h1>Tell us about your game</h1>
              <textarea onChange={aboutInput}></textarea>
            </div>
            <div className={styles.experiencecontainer}>
              <div className={styles.experience}>
                <h1>Is your game on the App Store/Google play store?</h1>
              </div>
              <div className={styles.button}>
                <div
                  className={styles.yesbutton}
                  onClick={() => setClickButton(true)}
                >
                  Yes
                </div>
                <div
                  className={styles.nobutton}
                  onClick={() => setClickButton(false)}
                >
                  No
                </div>
              </div>
            </div>
            <div className={styles.attachlink}>
              <h1 className={styles.attachtext}>
                {">"} Not on store? Attach the link here
              </h1>
              <input type="text" onChange={attachLinkInput}></input>
            </div>
            <div className={styles.teamcontainer}>
              <h1>Studio detail</h1>
            </div>
            <div className={styles.nameandportfoliolink}>
              <div className={styles.fullname}>
                <h1>Full name</h1>
                <input type="text" onChange={fullNameInput}></input>
              </div>
              <div className={styles.portfoliolink}>
                <h1>Portfolio link</h1>
                <input type="text" onChange={portfolioLinkInput}></input>
              </div>
            </div>
            <div className={styles.studionameandteamsize}>
              <div className={styles.studioname}>
                <h1>Studio name</h1>
                <input type="text" onChange={studioNameInput}></input>
              </div>
              <div className={styles.teamsize}>
                <h1>Team size</h1>
                <input type="text" onChange={teamSizeInput}></input>
              </div>
            </div>
            <div className={styles.emailcontainer}>
              <div className={styles.email}>
                <h1>email</h1>
                <input
                  type="email"
                  onChange={emailInput}
                  className={styles.emailinput}
                ></input>
                <div className={styles.mailalertmessage}></div>
              </div>
            </div>
          </form>
          <button className={styles.submitbutton} onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default PublishingSubmit;
