import React from "react";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";
import insta from "../../image/socialicon/instagram.png";
import facebook from "../../image/socialicon/facebook.png";
import youtube from "../../image/socialicon/youtube.png";
import appleIcon from "../../image/appstoreicon/btn_putter_appstore.png";
import googleIcon from "../../image/appstoreicon/btn_putter_google.png";
import emailIcon from "../../image/footericon/img_contact.png";
import locationIcon from "../../image/footericon/img_location.png";

const Footer = (props) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <li className={styles.menu}>
          <h1>Menu</h1>
          <Link to="/">Home</Link>
          <Link to="/games">Games</Link>
          {/* <Link to="/about" onClick={scrollTop}>
            About
          </Link> */}

          <Link to="/blog">Blog</Link>
          {/* <Link>Content</Link> */}
        </li>
        <li className={styles.submit}>
          <h1>Submit Your Game</h1>
          <Link to="/publish">Publishing</Link>
          <Link to="/partnership">Partnership</Link>
        </li>
        <li className={styles.mondayoff}>
          <div>
            <h1>©2023 MondayOFF</h1>
          </div>
          <div className={styles.contents}>
            <a
              className={styles.Privacy}
              href="https://mondayoff.me/privacyPolicy.html"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
          </div>
        </li>
        <li className={styles.iconcontainer}>
          <div className={styles.socialicon}>
            <a
              href="https://www.facebook.com/MondayOffGame/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCgYNSY4mEvtTjGUG7L56k6w"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>

            <a
              href="https://www.instagram.com/mondayoff_official/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} alt="instagram" className={styles.insta} />
            </a>
          </div>
          <div className={styles.storeIcon}>
            <a
              href="https://apps.apple.com/us/developer/mondayoff/id1448226898"
              target="_blank"
              rel="noreferrer"
              className={styles.apple}
            >
              <img src={appleIcon} alt="apple" />
            </a>
            <a
              href="https://play.google.com/store/apps/developer?id=MondayOFF&hl=ko&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleIcon} alt="google" />
            </a>
          </div>
          <div className={styles.companyinfo}>
            <div className={styles.mondayemail}>
              <img src={emailIcon} alt="" />
              <p>contact@mondayoff.me</p>
            </div>
            <div className={styles.mondayaddress}>
              <img src={locationIcon} alt="" />
              <div className={styles.address}>
                <p>14F, 465, Bongeunsa-ro, Gangnam-gu,</p>
                <p>Seoul, Republic of Korea</p>
              </div>
            </div>
          </div>
        </li>
      </div>
    </footer>
  );
};
export default Footer;
