import React from "react";
import styles from "../../articles.module.scss";
import top3MistakeMain from "../../../../image/blog/writing/top3mistake/top3mistakemain.png";
import aleIcon from "../../../../image/articles/Ale.png";
const TopMistake = (props) => {
  return (
    <article className={styles.container}>
      <img src={top3MistakeMain} alt="thumbnail" className={styles.thumbnail} />
      <h1 className={styles.title}>
        Top 3 Mistakes Made by Hyper Casual Developers
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={aleIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Alejandra Caceres</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2021. 11. 10</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <h2>1. Not creating a simple prototype for early testing.</h2>
        <p>
          Many times developers take very long to create a prototype, and this
          is understandable. As a developer, it is easy to get carried away when
          creating the best game possible as you pictured it on your mind. It is
          crucial to test your game in its early stage to see if it is worth
          completing and if the audience enjoys it. An early test will also
          allow you to know what needs to be changed on your gameplay and see
          which colors players like the most. All of this crucial information is
          obtained on the first tests.
        </p>
        <p>
          Also, now more than ever, quickly making a game based on a social
          media or pop culture trend is critical. As seen in the recent charts
          creating a game at the correct time allows you to be at the top. For
          this very reason, it is crucial to test your game in its early stage
          or develop a prototype in a short amount of time.
        </p>

        <h2>2. Perfecting the design and forgetting about the gameplay.</h2>
        <p>
          This is another common mistake made in the early phases of Hyper
          casual game development. Many times, developers get very absorbed in
          the game design and every aspect of it. When creating a prototype,
          make sure to focus on the gameplay and its mechanics. The gameplay and
          mechanics are what make your game special. After the prototype and
          testing it, you can fix the small details that you wish to add. Just
          remember to keep it simple at first and not overcrowd your game since
          it can be overwhelming for the player. Focus on the gameplay first-
          the details come later.
        </p>

        <h2>3. Giving up on Hyper casual after failing a couple of times.</h2>
        <p>
          In Hyper casual, many prototypes will fail, especially since this
          market moves so fast and trends change almost every week. Only one out
          of 100 games will make it to the tops charts. For this very reason,
          you should not give up on developing games quickly. You must try again
          and see what works. One day, your game will become a success and
          eventually be at the top charts steadily.
        </p>
        <p>
          Of course, it is discouraging for your game to not do well on tests
          and have to start from zero. As a Hyper casual developer, you never
          know if your next prototype test will succeed. Also, just because the
          first testing did not do as well as expected, it does not mean you
          have to give up on your previous prototype. With testing and feedback
          from your publisher, you can improve your game to have a promising
          result.
        </p>
        <p>
          At MondayOFF, we can provide you with the help and feedback you need
          for your game to become a hit! Our publishing services and managers
          will help you in every step and give you the aid and attention for
          your game to be at the top of the charts.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default TopMistake;
