import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import styles from "./publishpage.module.css";
import header from "../header/header.module.css";
import classNames from "classnames";
import gsap from "gsap";
import img1 from "../../image/gameicon/img1.jpg";
import img2 from "../../image/gameicon/img2.jpg";
import img3 from "../../image/gameicon/img3.jpg";
import img4 from "../../image/gameicon/tinyrun.jpg";
import img5 from "../../image/gameicon/img5.jpg";
import img6 from "../../image/gameicon/img6.jpg";
import img7 from "../../image/gameicon/img7.jpg";
import img8 from "../../image/gameicon/img8.jpg";
import img9 from "../../image/gameicon/img9.jpg";
import img10 from "../../image/gameicon/img10.jpg";
import img11 from "../../image/gameicon/img11.jpg";
import publish1 from "../../image/publishpage/pu1.png";
import publish2 from "../../image/publishpage/pu2.png";
import publish3 from "../../image/publishpage/pu3.png";
import star1 from "../../image/img_banner1_star1.png";
import star2 from "../../image/img_banner1_star2.png";
import star3 from "../../image/img_banner1_star2_1.png";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import getGA from "../../service/ga.js";
import { Helmet } from "react-helmet-async";
import favicon from "../../image/img_mondayoff_s.png";
const PublishPage = (props) => {
  useEffect(() => {
    getGA();
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleWindowsize = useCallback(() => {
    setWindowSize(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowsize);
    return () => {
      window.removeEventListener("resize", handleWindowsize);
    };
  });
  useEffect(() => {
    const menulist = document.getElementsByClassName(header.menulist);
    const logoImg = document.getElementsByClassName(header.logo);
    menulist[0].classList.remove(header.active);
    logoImg[0].classList.remove(header.active);
  }, []);
  useEffect(() => {
    const nav = document.getElementsByClassName(header.nav);
    const gameMenu = document.getElementsByClassName(header.gamemenu);
    const gameText = document.getElementsByClassName(header.gametext);
    const publishPage = document.getElementsByClassName(header.publishmenu);
    const publishText = document.getElementsByClassName(header.publishtext);
    const partnershipPage = document.getElementsByClassName(
      header.partnershipmenu
    );
    const partnershipText = document.getElementsByClassName(
      header.partnershiptext
    );
    const articleMenu = document.getElementsByClassName(header.articlemenu);
    const articleText = document.getElementsByClassName(header.articletext);
    if (props.match.path === "/publish") {
      window.history.scrollRestoration = "manual";

      if (windowSize < 769) {
        nav[0].style.position = "sticky";
        publishPage[0].style.borderBottom = "";
        publishText[0].style.fontWeight = 700;
        publishText[0].style.color = "#ffff";
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        gameText[0].style.color = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        partnershipText[0].style.color = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
        articleText[0].style.color = "";
      } else {
        nav[0].style.position = "static";
        publishPage[0].style.borderBottom = "5px solid #ffff";
        publishText[0].style.fontWeight = 700;
        gameMenu[0].style.borderBottom = "";
        gameText[0].style.fontWeight = "";
        partnershipPage[0].style.borderBottom = "";
        partnershipText[0].style.fontWeight = "";
        articleMenu[0].style.borderBottom = "";
        articleText[0].style.fontWeight = "";
      }
    }
  }, [props.match.path, windowSize]);

  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
    gsap
      .to(window, {
        scrollTo: { y: 0, x: 0, autoKill: false },
      })
      .then(
        gsap.to(".moveimg", {
          scrollTrigger: {
            trigger: styles.container,
            start: "3% top",
            end: "30% top",
            scrub: true,
            markers: false,
          },
          y: -150,
          ease: "none",
        })
      );
    ScrollTrigger.refresh();
  }, [props.match.path]);

  return (
    <>
      <Helmet>
        <title>MondayOFF - Publish</title>
        <link rel="icon" href={favicon} />
        <meta
          name="description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          property="og:site_name"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="og:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
        />
        <meta
          property="og:url"
          content="https://www.mondayoff.me/publish"
          data-react-helmet="true"
        />
        <meta
          property="twitter:card"
          content="summary"
          data-react-helmet="true"
        />
        <meta
          property="twitter:title"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
        <meta
          property="twitter:url"
          content="https://www.mondayoff.me/publish"
          data-react-helmet="true"
        />
        <meta
          property="twitter:image"
          content="https://www.mondayoff.me/mondayoff_logo_profile.jpg"
          data-react-helmet="true"
        />
        <meta
          property="twitter:description"
          content="MondayOFF - Publish"
          data-react-helmet="true"
        />
      </Helmet>
      <div className={styles.container}>
        <header className={styles.header}>
          <h1 className={styles.title}>Publishing</h1>
          <a
            href="https://dashboard.mondayoff.me"
            target="_blank"
            rel="noreferrer"
            className={styles.button}
          >
            Submit Your Game
          </a>
        </header>
        <div className={styles.topmovebutton} onClick={onClick}>
          <div className={styles.toparrow}>⌃</div>
        </div>
        <article className={styles.gridcontainer}>
          <div className={styles.center}>
            <h1>Let’s create the next trend!</h1>
            <a
              href="https://dashboard.mondayoff.me"
              target="_blank"
              rel="noreferrer"
              className={styles.centerbutton}
            >
              Submit Your Game
            </a>
          </div>
          <img
            className={classNames(styles.imgarea1, "moveimg")}
            src={img1}
            alt=""
          />
          <img
            className={classNames(styles.imgarea2, "moveimg")}
            src={img2}
            alt=""
          />
          <img
            className={classNames(styles.imgarea3, "moveimg")}
            src={img3}
            alt=""
          />
          <img
            className={classNames(styles.imgarea4, "moveimg")}
            src={img4}
            alt=""
          />
          <img
            className={classNames(styles.imgarea5, "moveimg")}
            src={img5}
            alt=""
          />
          <img
            className={classNames(styles.imgarea6, "moveimg")}
            src={img6}
            alt=""
          />
          <img
            className={classNames(styles.imgarea7, "moveimg")}
            src={img7}
            alt=""
          />
          <img
            className={classNames(styles.imgarea8, "moveimg")}
            src={img8}
            alt=""
          />
          <img
            className={classNames(styles.imgarea9, "moveimg")}
            src={img9}
            alt=""
          />
          <img
            className={classNames(styles.imgarea10, "moveimg")}
            src={img10}
            alt=""
          />
          <img
            className={classNames(styles.imgarea11, "moveimg")}
            src={img11}
            alt=""
          />
        </article>
        <article className={styles.scriptcontainer}>
          <section className={styles.script1}>
            <div>
              <img className={styles.scriptimg1} src={publish1} alt="first" />
            </div>
            <div className={styles.scripttext1}>
              <h1 className={styles.scripttitle}>
                Experienced and Dedicated Team
              </h1>
              <p className={styles.scriptcontext}>
                Working on a game? Let’s bring out its full potential! With
                year-long expertise in marketing, data analysis and development,
                our enthusiastic team will be with you step by step to bring
                your game to the top of the charts.
              </p>
            </div>
          </section>
          <section className={styles.script2}>
            <div className={styles.scripttext2}>
              <h1 className={styles.scripttitle}>
                Expertise on User Acquisition
              </h1>
              <p className={styles.scriptcontext}>
                Imagine people all over the world enjoying your game. Over the
                last 6 months we have surpassed 50 million downloads. With our
                specialized User Acquisition team and vast experience, we will
                bring your game to millions of players on a global stage.
              </p>
            </div>
            <div>
              <img className={styles.scriptimg2} src={publish2} alt="second" />
            </div>
          </section>
          <section className={styles.script3}>
            <div>
              <img className={styles.scriptimg3} src={publish3} alt="third" />
            </div>
            <div className={styles.scripttext3}>
              <h1 className={styles.scripttitle}>Optimized Monetization</h1>
              <p className={styles.scriptcontext}>
                For us, transparency is key! We share the marketing data with
                you to provide feedback and further improve your game. Through
                our data-driven approach, we optimize marketing strategies in
                cooperation with Top Ad networks to maximize revenue and LTV.
              </p>
            </div>
          </section>
        </article>
        <section className={styles.footercontainer}>
          <h1>Pitch your game, we’ll take care of the rest.</h1>
          <div className={styles.footercontext}>
            <p>
              We know your time is valuable. That’s why we focus on efficiency.
              We know that repeated and frequent tests are tiring. Our testing
              process doesn’t take more than a month. In just 3 weeks your game
              will be ready.
            </p>
          </div>
          <img src={star1} alt="" className={styles.star1} loading="lazy" />
          <img src={star2} alt="" className={styles.star2} loading="lazy" />
          <img src={star3} alt="" className={styles.star3} loading="lazy" />
        </section>
      </div>
    </>
  );
};
export default PublishPage;
