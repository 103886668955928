import React from "react";
import styles from "../../articles.module.scss";
import thirdThumbnail from "../../../../image/articles/Article03.png";
import one from "../../../../image/articles/PracticalTips/one.gif";
import two from "../../../../image/articles/PracticalTips/two.gif";
import three from "../../../../image/articles/PracticalTips/three.gif";
import four from "../../../../image/articles/PracticalTips/four.gif";
import five from "../../../../image/articles/PracticalTips/five.gif";
import six from "../../../../image/articles/PracticalTips/six.gif";
import seven from "../../../../image/articles/PracticalTips/seven.gif";
import eight from "../../../../image/articles/PracticalTips/eight.gif";
import nine from "../../../../image/articles/PracticalTips/nine.gif";
import ten from "../../../../image/articles/PracticalTips/ten.gif";
import eleven from "../../../../image/articles/PracticalTips/eleven.png";
import lenaIcon from "../../../../image/articles/Lena.png";

const PracticalTips = () => {
  return (
    <article className={styles.container}>
      <img className={styles.thumbnail} src={thirdThumbnail} alt="thumnail" />
      <h1 className={styles.title}>
        Practical Tips for Making Impactful Creatives
      </h1>
      <div className={styles.profile} data-content="profile">
        <img src={lenaIcon} content="profile" alt="profileImg" />
        <div content="info">
          <span content="name">
            <span>Lena</span>
            <span>•</span>
            <span>Publishing Team</span>
          </span>
          <span content="date">2023. 5. 31</span>
        </div>
      </div>
      <section className={styles.article_contents}>
        <p>
          In both hyper-casual and hybrid-casual categories alike, ad creatives
          play a major role. In{" "}
          <a
            className={styles.link}
            href="https://events.mondayoff.me/hybrid-casual"
            target="_blank"
            rel="noreferrer"
          >
            our article
          </a>{" "}
          on both models, we mentioned the importance of maintaining a low CPI
          and keeping the ad content straightforward. A carefully executed
          creative video could determine whether viewers convert into dedicated
          players or if they will ignore it and keep scrolling. It can be tricky
          to decide how and which unique selling points of your game would be
          best to feature. In this blog, we will share some tips on how you can
          leverage your creatives.
        </p>
        <h2>1. Diversify Your Creatives With a Range of Scenarios</h2>
        <p>
          One of the key purposes of the creative videos is to emphasise to
          potential players the replay value of the game. The best way to do
          this is to diversify the scenarios used in those ads and show that it
          offers multiple level variations, environments, and challenges. By
          creating multiple creatives, each with a distinctive scenario, you can
          appeal to a wider target audience.
        </p>
        <p>
          There are multiple aspects of your game that you can choose to unveil
          in the videos. For example, “Win” and “Fail” cases are typical
          scenarios shown in many hyper-casual game ads as they clearly
          communicate the game rules and conditions. “Fail” scenarios can be
          especially effective as they build frustration in viewers and
          challenge them, prompting them to feel that they can do better than as
          shown in the video and drive them to install the game. On the other
          hand, showing “Win” cases can help to create anticipation and desire
          to experience similar victories. Unusual but entertaining obstacles
          create expectations about the difficulty level and evokes the “just
          one more try" mentality that makes these games addictive. Characters
          appearing in unlockable or purchasable skins and clothes can help
          players explore different styles and understand the level of in-game
          customisation allowed.
        </p>
        <p>
          MondayOFF's Mayhem Shooter exemplifies this well. Its straightforward
          gameplay and simple design is enough to captivate your attention. Yet,
          the strategic display of various weapons and the showcase of enemies
          and obstacles such as explosive barrels truly elevate the viewing
          experience. The creatives made use of the skins and weapons library to
          combat the feeling of repetitiveness of the main characters presence
          and level map layouts. By highlighting the most exciting parts of your
          game and what actions the viewers can take, you are bringing them on a
          short adrenaline-fueled adventure. Mayhem Shooter has since gone on to
          achieve nearly 800k downloads and counting shortly after being
          globally published.
        </p>
        <div data-content="4">
          <img src={six} content="height_4" alt="img"></img>
          <img src={four} content="height_4" alt="img"></img>
          <img src={five} content="height_4" alt="img"></img>
          <img src={ten} content="height_4" alt="img"></img>
        </div>

        <h2>2. Convey the Game's Goal in the First 5 Seconds</h2>
        <p>
          A typical ad creative video is usually between 15-30 seconds long.
          Take into consideration an even shorter amount of time. According to
          <a
            className={styles.link}
            href="https://www.is.com/creative-benchmarks/home.html"
            target="_blank"
            rel="noreferrer"
          >
            IronSource
          </a>
          , the average time-to-engage rate for hyper-casual creatives is only
          3.5 seconds! Optimise the content and make full use of the time by
          clarifying the main goal, general theme and mechanics early into the
          video. Your ad creatives will often be competing with many others of
          the same genre, and you will need to show what sets your game apart
          from the rest. We recommend highlighting the special features of your
          games, such as interesting rewards, addictive gameplay, or ability to
          achieve very high records.
        </p>
        <p>
          Take a look at these examples from MondayOFF and how they explain the
          gist of the game:
        </p>
        <div data-content="3">
          <img src={three} content="height_3" alt="img"></img>
          <img src={seven} content="height_3" alt="img"></img>
          <img src={nine} content="height_3" alt="img"></img>
        </div>

        <p>
          Remember that hyper-casual games are made for the general audience,
          with gameplay and mechanics usually being straightforward and easy to
          understand - employ this in your creatives-making process to appeal to
          the masses.
        </p>
        <h2>3. Pick the Right Colour Schemes to Elevate Your Creatives</h2>
        <p>
          Bright and vibrant hues in hyper-casual games are always a good idea,
          but just as with everything, balance is key. Make sure that you can
          achieve exciting visuals with harmonious and consistent colour
          palettes that will not overwhelm the players. A clear colour scheme
          and appropriate contrast can help viewers more easily shift their
          focus to the most important parts, such as the targets and main
          character.
        </p>
        <p>
          Simple does not always mean less! Take for example our game Like a
          Pizza. The game’s visuals revolve around a few shades that help easily
          tell apart areas and objects, while keeping the mood light and
          easygoing.
        </p>
        <div data-content="2">
          <img src={one} content="height_2" alt="img"></img>
          <img src={two} content="height_2" alt="img"></img>
        </div>
        <p>
          When looking at these creatives, viewers can immediately identify the
          most relevant elements. Here, you know who the main character is -
          while all the characters are of the same shape, it is easily
          distinguishable with an orange costume. They also know exactly where
          to go and how much they can earn, since upgrade stations are darkened
          against the bright yellow floor and indicate simple amounts. The most
          satisfying part of playing, earning and retrieving money, is also
          easily spotted as well.
        </p>
        <p>
          If deciding on colours seems like a daunting task, you can always
          start out with the simple primary colours of Red, Blue and Green. The
          RGB theory is already a popular method among developers to enhance
          visuals by carefully mixing colours to create various hues along with
          different intensities.
        </p>
        <p>
          Red is an obvious choice to represent high energy and is usually seen
          depicted in enemies, or to alert about an incoming danger. Blue is a
          non-distracting colour that is often used for backgrounds. It makes
          the area look clean and yet to be explored, compelling players to be
          interested in what is beyond their play area and new things to unlock.
          Green is another simple solution to paint environments and to bring
          attention to positive upgrades or power-ups.
        </p>
        <p>
          A sleek and “clean” does not always have to be the default choice.
          However, the main objective should remain the same: to achieve a
          visual distinction between important areas and help players focus on
          the main things. Be sure to research and refer to other successful
          hypercasual ads colour schemes, and experiment with different versions
          through a/b testing to find what works best for your game,
        </p>
        <h2>
          4. Direct the Audience to focus on feeling an emotion through each
          creative
        </h2>
        <p>
          It goes without saying that most advertising content aims to elicit
          excitement and interest from viewers. Many focus on evoking positively
          associated feelings. What is often overlooked is a whole other range
          of emotions that hold the power to change the behaviour of a person
          and prompt them to take some action (in this case, to install and play
          the game). These are some general concepts that you can tap into and
          boost the impact of your creatives:
        </p>
        <ul>
          <li>
            Curiosity: Bring up the intriguing elements of your game, such as a
            mystery box or an interesting way to manipulate the gameplay
            mechanics.{" "}
          </li>
          <li>
            Surprise: Incorporate unpredictable visuals that catch the viewers
            off guard, such as unexpected enemies appearing, sudden level
            failure, or an unexpected plot element.
          </li>
          <div data-content="1">
            <img src={eight} content="height_1" alt="img"></img>
          </div>
          <li>
            Frustration: Display a challenge through a series of struggles to
            make viewers sense that they may know a better way to complete the
            level.{" "}
          </li>
          <li>
            Satisfaction/Confidence: The gist of hyper-casual games is giving
            players an easy win, so by showcasing very high scores and large
            amounts of money earned, viewers will have a much higher sense of
            achievement and confidence in their skills
          </li>
          <li>
            Relaxation: Depict gameplay in a calm and relaxed manner, with a
            smooth flow. Repetitive actions, satisfying transitions and
            meditative visual effects are common tactics.
          </li>
          <li>
            Nostalgia: Include gameplay elements, sound effects or graphics that
            are reminiscent of classical games or a certain era.{" "}
          </li>
        </ul>
        <h2>5. Bonus Tips from MondayOFF’s Publishing Managers!</h2>
        <p>
          We always remind game developers that you do not need to have
          professional editing skills to create amazing creatives, but you
          certainly need a good strategy and vision of what you are trying to
          relay to potential players. Many assume that adding a ton of effects
          and packing as much content in is for the better, but we advise to
          keep the creatives straightforward enough to relay the core gameplay
          and engage viewers at the same time.
        </p>
        <p>
          There is no way to “hack” the Facebook Ads algorithm, editing choices
          can have a massive impact on it. For example, we often see cases where
          an ad opens with a “fade-in/fade-out” effect that causes the first
          second of the video to appear as a black screen. The absence of
          displayed content in the first few seconds causes the algorithm to not
          allocate the daily budget spending to the ad and affects the overall
          campaign data.
        </p>
        <div data-content="1">
          <img src={eleven} content="width_1" alt="img"></img>
        </div>
        <p>
          Making creatives is a tricky process - even the most well-made videos
          could rack up a high CPI.
        </p>
        <p>
          What you can control is creating an ad creative that aligns with the
          overall tone and experience of the hypercasual game. Successful
          creatives should provide a glimpse into the unique and enjoyable
          aspects of the game, carefully appeal to viewer’s emotions, and
          capture their attention with just the right amount of fun visuals.
          These strategies can help you maximise the impact of your ads by
          resonating with the right audience.
        </p>
        <p>
          At MondayOFF, our creative team vigorously tests anywhere between
          80-100 creatives per game to ensure that the very best are selected
          for scale-up. Start publishing with us to gain access to our in-house
          team’s expertise and support your game development!
        </p>
        <p>
          Follow MondayOFF’s{" "}
          <a
            className={styles.link}
            href="https://www.linkedin.com/company/mondayoff-inc"
            target="_blank"
            rel="noreferrer"
          >
            LinkedIn
          </a>{" "}
          and{" "}
          <a
            className={styles.link}
            href="https://lnkd.in/gFPeeQ5A"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>{" "}
          to stay updated about upcoming events and to receive useful
          information about hyper-casual and hybrid-casual games.
        </p>
      </section>
      <section className={styles.dashboard_link_contents}>
        <p>Publish with MondayOFF</p>
        <p>Make your next big hit with flexible KPIs!</p>
        <a
          href="https://dashboard.mondayoff.me"
          target="_blank"
          rel="noreferrer"
        >
          Submit Your Game
        </a>
      </section>
    </article>
  );
};

export default PracticalTips;
